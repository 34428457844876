import { getAPIGETResponse, getAPIPOSTResponse, getAPIDELETEResponse, getAPIPUTResponse } from './BaseService';
import moment from 'moment';

const baseUrl = process.env.REACT_APP_STUDIO_API_BASE_URL;

export async function GetStreams() {
    return await getAPIGETResponse(baseUrl, '/Streams/streams', true);
}

export async function GetStream(externalId) {
    return await getAPIGETResponse(baseUrl, `/Streams/stream/${externalId}`, true);
}

export async function UpdateStream(streamUpdate) {
    streamUpdate.startDate = moment.utc(streamUpdate.startDate).format();
    streamUpdate.endDate = moment.utc(streamUpdate.endDate).format();
    return await getAPIPUTResponse(baseUrl, '/Streams/update', streamUpdate);
}

export async function GetCourts() {
    return await getAPIGETResponse(baseUrl, '/Courts/courts', true);
}

export async function GetCameras() {
    return await getAPIGETResponse(baseUrl, '/Cameras/cameras', true);
}

export async function StopStream(externalId) {
    const stopStreamPayload =  { liveStreamExternalId: externalId };
    console.log(stopStreamPayload);
    return await getAPIPOSTResponse(baseUrl, `/Streams/stop/${externalId}`);
}

export async function StartStream(externalId) {
    return await getAPIPOSTResponse(baseUrl, `/Streams/start/${externalId}`);
}

export async function DeleteStream(externalId) {
    return await getAPIDELETEResponse(baseUrl, `/Streams/${externalId}`);
}

export async function ToggleCamera(cameraId, enable) {
    if (enable) {
        return getAPIPUTResponse(baseUrl, `/Cameras/enable-camera/${cameraId}`);
    } else {
        return getAPIPUTResponse(baseUrl, `/Cameras/disable-camera/${cameraId}`);
    }
}