import React, { useState } from "react";

export default function Accordion({ question, answere, link, sub }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{question}</div>
        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && (
        <div className="accordion-content">
          {answere}{" "}
          {link && (
            <span>
              <a href={link} target="_blank">
                {link}
              </a>
              <span>
                {" "}
                or{" "}
                <a href="https://fast.com/" target="_blank">
                  {" "}
                  https://fast.com
                </a>
              </span>
            </span>
          )}
          <br></br>{sub && (
            sub
          )}
        </div>
      )}
    </div>
  );
}
