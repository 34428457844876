import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useLocation } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, location: props.location };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function ErrorBoundaryWithRouter(props) {
  const location = useLocation();
  return (
    <>
      <ErrorBoundary location={location} {...props} />
    </>
  );
}

export default ErrorBoundaryWithRouter;
