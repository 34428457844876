import { useEffect, useState } from "react";
import { ScheduleStream } from "../services/Streams";

const useScheduleStreams = () => {
  const [scheduleRequest, setScheduleRequest] = useState([]);
  const [responseMessage, setResponseMessage] = useState([]);
  const [scheduling, setScheduling] = useState();
  const [isSchedulingStream, setIsSchedulingStream] = useState(false);

  useEffect(() => {
    setResponseMessage([]);
    handleRequests();
  }, [scheduleRequest]);

  const handleRequests = async () => {
    const tempArray = [];
    for (let i = 0; scheduleRequest.length > i; i++) {
      const request = scheduleRequest[i];
      setIsSchedulingStream(true);
      setScheduling(request);
      const response = await ScheduleStream(request).catch((e) => {
        let errorMessage;
        if (e.response) {
          errorMessage = handleErrorMessage(e.response.data);
        } else {
          errorMessage = e.toString();
        }
        const errorResponse = {
          court: request.court,
          status: "error",
          message: errorMessage,
        };
        console.log("This is SAD responseMessage in hook: ", errorMessage);
        tempArray.push(errorResponse);
        setResponseMessage(Object.assign([], tempArray));
        setIsSchedulingStream(false);
      });
      if (response) {
        const successResponse = {
          court: request.court,
          status: "success",
          success: true,
          message: "Stream successfully scheduled",
        };
        console.log("This is HAPPY responseMessage in hook: ", successResponse);
        tempArray.push(successResponse);
        setIsSchedulingStream(false);
        setResponseMessage(Object.assign([], tempArray));
      }
    }
  };

  return [
    setScheduleRequest,
    scheduling,
    responseMessage,
    scheduleRequest,
    isSchedulingStream,
  ];
};

const handleErrorMessage = (error) => {
  switch (error) {
    case "ALREADY_SCHEDULED":
      return "Stream is already scheduled for this court during this time";
    case "START_DATE_TOO_FAR_IN_PAST":
      return "Stream is too far in the past";
    case "START_DATE_IS_AFTER_STOP_DATE":
      return "Can't schedule a stream with an end date that is before the start date";
    case "STREAM_LENGTH_NOT_ALLOWED":
      return "Can't schedule a stream that is longer than 15 hours";
    case "COURT_NOT_AVAILABLE":
      return "Court is not available or active";
    default:
      return "Something went wrong...";
  }
};

export default useScheduleStreams;
