import React, { useEffect, useState } from 'react';
import { GetEvents } from '../services/Clubs';

export default function UseEvents(clubId) {

    const [events, setEvents] = useState({ isLoading: true, result: false });

    const refresh = () => {
        if (!clubId) {
            return;
        }

        setEvents({ isLoading: true });

        GetEvents(clubId).then((result) => {
            setEvents({ result: result, isLoading: false });
        });
    }

    useEffect(() => {
        refresh();
    }, [clubId]);

    return [events, refresh];
}