import React, { useEffect, useState } from 'react';
import { GetCourts } from '../services/Studio';

export default function UseCourts() {

    const [courts, setCourts] = useState({ isLoading: false, result: false });

    useEffect(() => {
        setCourts({ isLoading: true });

        GetCourts().then((result) => {
            setCourts({ result: result, isLoading: false });
        });
    }, []);

    return courts;
}