export const SET_VALIDATED = "SET_VALIDATED";
export const SET_ALERT = "SET_ALERT";
export const SET_CLUB = "SET_CLUB";
export const SET_IS_CREATING_EVENT = "SET_IS_CREATING_EVENT";
export const SET_DISPLAY_SUCCESS_MODAL = "SET_DISPLAY_SUCCESS_MODAL";
export const SET_LOCAL_IMAGE_FILE = "SET_LOCAL_IMAGE_FILE";
export const SET_IS_PAY_PER_VIEW_ENABLED = "SET_IS_PAY_PER_VIEW_ENABLED";
export const SET_IS_LOADING_STRIPE_ACCOUNT = "SET_IS_LOADING_STRIPE_ACCOUNT";
export const SET_PARENT_EVENTS = "SET_PARENT_EVENTS";
export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";
export const SET_CLUB_OBJECT = "SET_CLUB_OBJECT";

export function reducer(state, action) {
  switch (action.type) {
    case SET_VALIDATED:
      return { ...state, validated: action.payload };
    case SET_ALERT:
      return { ...state, alert: action.payload };
    case SET_CLUB:
      return { ...state, club: action.payload };
    case SET_IS_CREATING_EVENT:
      return { ...state, isCreatingEvent: action.payload };
    case SET_DISPLAY_SUCCESS_MODAL:
      return { ...state, displaySuccessModal: action.payload };
    case SET_LOCAL_IMAGE_FILE:
      return { ...state, localImageFile: action.payload };
    case SET_IS_PAY_PER_VIEW_ENABLED:
      return { ...state, isPayPerViewEnabled: action.payload };
    case SET_IS_LOADING_STRIPE_ACCOUNT:
      return { ...state, isLoadingStripeAccount: action.payload };
    case SET_CURRENT_EVENT:
      return { ...state, currentEvent: action.payload };
    case SET_CLUB_OBJECT:
      return { ...state, clubObject: action.payload };
    default:
      throw new Error("Unhandled action type: " + action.type);
  }
}
