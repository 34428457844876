import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
/* Components */
import Header from "../components/Header";
import {
  Container,
  Spinner,
  Row,
  Col,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useParams } from "react-router-dom";

/* Hooks */
import UseStream from "../hooks/useStream";
import useEvents from "../hooks/useEvents";
import useUser from "../hooks/useUser";
import UseCourts from "../hooks/useCourts";
import useHasClubRole from "../hooks/useHasClubRole";

import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
import { UpdateStream } from "../services/Studio";
import moment from "moment";

function StreamsEdit({ user }) {
  const navigate = useNavigate();
  let { externalId } = useParams();
  const currentUser = useUser();
  const hasClubRole = useHasClubRole(user);
  const [updatedStream, setUpdatedStream] = useState();
  const [events, setEvents] = useEvents(currentUser?.result?.clubId);
  const [stream, refresh] = UseStream({ externalId });
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [startDateTime, setStartDateTime] = useState(new Date());
  const courts = UseCourts();
  const [validated, setValidated] = useState(false);
  const [isUpdatingStream, setIsUpdatingStream] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    variant: "success",
    heading: "",
    bodytext: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
    } else {
      setIsUpdatingStream(true);

      const updateStreamWithEnd = {
        ...updatedStream,
        endDate: moment.utc(endDateTime).toISOString(),
        startDate: moment.utc(startDateTime).toISOString(),
      };
      UpdateStream(updateStreamWithEnd)
        .then((result) => {
          refresh();
          setAlert({
            ...alert,
            show: true,
            heading: "Stream updated!",
            bodytext: "Stream was successfully updated.",
            variant: "success",
          });
        })
        .finally(() => {
          setIsUpdatingStream(false);
        });
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    if (stream.result) {
      setUpdatedStream({
        ...stream.result,
        startDate: moment.utc(stream.result.startDate).format(),
        endDate: moment.utc(stream.result.endDate).format(),
      });
      setEndDateTime(
        new Date(moment.utc(stream.result.endDate).local().format())
      );
      setStartDateTime(
        new Date(moment.utc(stream.result.startDate).local().format())
      );
    }
  }, [stream]);

  if (!hasClubRole) {
    navigate("unauthorized");
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className="mt-4 mb-2">
            <Alert
              show={alert.show}
              variant={alert.variant}
              onClose={() => setAlert({ ...alert, show: false })}
              dismissible
            >
              <Alert.Heading>{alert.heading}</Alert.Heading>
              <p>{alert.bodytext}</p>
            </Alert>
            <h3 className="title">Edit stream</h3>
            {!stream.isLoading && stream.result && updatedStream && (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formStreamTitle">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter stream name"
                    defaultValue={stream.result?.title}
                    onChange={(e) => {
                      setUpdatedStream({
                        ...updatedStream,
                        title: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formStreamChannel">
                  <Form.Label>Channel</Form.Label>
                  <Form.Control
                    required
                    disabled
                    type="text"
                    placeholder="Loading..."
                    value={stream.result.channel}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Court</Form.Label>
                  <Form.Control
                    disabled={!stream.result.isPossibleToStart}
                    as="select"
                    defaultValue={stream.result.court}
                    onChange={(e) => {
                      setUpdatedStream({
                        ...updatedStream,
                        court: e.target.value,
                      });
                    }}
                  >
                    {courts &&
                      courts.result &&
                      courts.result.map((item, index) => {
                        return (
                          <option key={index} value={item.courtId}>
                            {item.description}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formIsPrivateStreamCheckbox">
                <Form.Check
                  type="checkbox"
                  label= "Private stream"
                  defaultChecked={stream.result.isPrivate}
                  onChange={(e) => {
                    setUpdatedStream({
                      ...updatedStream,
                      isPrivate: e.target.checked,
                    });
                  }}
                />
                <Form.Text className="text-muted">
                  Checking this means that the stream will be visible only to you.
                </Form.Text>
              </Form.Group>
                <Form.Group controlId="formStreamStart">
                  <Form.Label>Stream start</Form.Label>
                  <DatePicker
                    disabled={!stream.result.isPossibleToStart}
                    className="form-control"
                    wrapperClassName="d-block"
                    selected={startDateTime}
                    onChange={(date) => {
                      setStartDateTime(date);
                      setUpdatedStream({ ...updatedStream, startDate: date });
                    }}
                    showTimeSelect
                    minDate={addDays(new Date(), 0)}
                    filterTime={filterPassedTime}
                    timeIntervals={5}
                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd HH:mm"
                  />
                </Form.Group>
                <Form.Group controlId="formStreamEnd">
                  <Form.Label>Stream end</Form.Label>
                  <DatePicker
                    disabled={!stream.result.isPossibleToStart}
                    className="form-control"
                    wrapperClassName="d-block"
                    selected={endDateTime}
                    onChange={(date) => {
                      setEndDateTime(date);
                      setUpdatedStream({
                        ...updatedStream,
                        endDate: endDateTime,
                      });
                    }}
                    showTimeSelect
                    maxDate={updatedStream.startDate}
                    filterTime={filterPassedTime}
                    timeIntervals={5}
                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd HH:mm"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Connected event</Form.Label>
                  <Form.Control
                    as="select"
                    value={updatedStream.eventId ?? "NO_EVENT"}
                    onChange={(e) =>
                      setUpdatedStream({
                        ...updatedStream,
                        eventId:
                          e.target.value === "NO_EVENT" ? null : e.target.value,
                      })
                    }
                  >
                    {(events?.result || []).map((item, index) => {
                      return (
                        <option key={index} value={item.eventId}>
                          {item.eventName}
                        </option>
                      );
                    })}
                    <option value={"NO_EVENT"}>No event connected</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formStreamManuallyCheckbox">
                  <Form.Check
                    disabled={!stream.result.isPossibleToStart}
                    type="checkbox"
                    label="I'll be streaming with OBS Studio"
                    defaultChecked={stream.result.isStudio}
                    onChange={(e) => {
                      setUpdatedStream({
                        ...updatedStream,
                        isStudio: e.target.checked,
                      });
                    }}
                  />
                  <Form.Text className="text-muted">
                    Only check this if you will be streaming with an external
                    streaming software from a PC or Mac. If you are using OBS
                    Studio, you can find instructions under "How to stream with
                    OBS Studio" manual under the "Help and tutorials" section.
                  </Form.Text>
                </Form.Group>
                <Button
                  disabled={isUpdatingStream}
                  className="mt-2"
                  variant="primary"
                  type="submit"
                >
                  {isUpdatingStream && (
                    <>
                      <Spinner
                        className="mr-1"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Just a second...
                    </>
                  )}
                  {!isUpdatingStream && <>Save</>}
                </Button>
              </Form>
            )}
          </Col>
        </Row>
        <Row>
          {stream.isLoading && (
            <Col className="d-flex justify-content-center align-items-center mt-5">
              <Spinner animation="border" />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default StreamsEdit;
