import React, { useState } from "react";

//wysiwyg
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../styles/theme.css";

export default function EditorContainer(props) {
  const [editorState, setEditorState] = useState(
    props.currentEvent && props.currentEvent.description
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(props.currentEvent.description)
          )
        )
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    props.onEditorStateChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    setEditorState(editorState);
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ["inline", "list", "link", "emoji"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic"],
          },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 20],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
        }}
        toolbarClassName="border border-dark-subtle rounded"
        wrapperClassName=""
        editorClassName="border border-1 rounded px-2 eventDescriptionWysiwyg"
        placeholder="Enter event description"
      />
    </div>
  );
}
