import { getAPIGETResponse, getAPIPOSTResponse, getAPIDELETEResponse, getAPIPUTResponse } from './BaseService';

const baseUrl = process.env.REACT_APP_CLUBS_API_BASE_URL;

export async function GetClub(clubId) {
        return await getAPIGETResponse(baseUrl, `/Clubs/${clubId}`, false);
}

export async function GetEvents(clubId) {
        return await getAPIGETResponse(baseUrl, `/Events/club/${clubId}`, false);
}

export async function GetFederationEventsToConnect(clubId) {
        return await getAPIGETResponse(baseUrl, `/Events/federationEventsToConnect/${clubId}`, false);
}

export async function GetEvent(id) {
        return await getAPIGETResponse(baseUrl, `/Events/${id}`, false);
}

export async function GetStripeAccount() {
        return await getAPIGETResponse(baseUrl, `/Clubs/stripe/account`, true);
}

export const GetStripeUrl = async (refreshUrl, returnUrl) => {
        return await getAPIPOSTResponse(baseUrl, '/Clubs/stripe/url', { refreshUrl: refreshUrl, returnUrl: returnUrl });
}

export async function UpdateEvent(event) {
        return await getAPIPUTResponse(baseUrl, '/Events', event);
}

export async function CreateEvent(event) {
        return await getAPIPOSTResponse(baseUrl, '/Events', event);
}

export async function DeleteEvent(eventId) {
        return await getAPIDELETEResponse(baseUrl, `/Events/${eventId}`);
}

export async function DeleteEventImage(eventExtId) {
        return await getAPIDELETEResponse(
          `${baseUrl}/Events/deleteImage?eventExtId=${eventExtId}`);
}

export async function UploadEventImage(eventId, imageData) {
        return await getAPIPOSTResponse(baseUrl, '/Events/uploadImage', { eventId: eventId, imageData: imageData });
}

export async function RemoveStreamFromEvent(eventExtId, streamExternalId) {
        return await getAPIPUTResponse(baseUrl, '/EventsV2/remove-stream-from-event', { eventExtId: eventExtId, liveEventExtId: streamExternalId });
}

export async function RemoveHighlightFromEvent(eventExtId, highlightExtId) {
        return await getAPIPUTResponse(baseUrl, '/EventsV2/remove-highlight-from-event', { eventExtId: eventExtId, highlightExtId: highlightExtId });
}
