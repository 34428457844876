import React, { useEffect, useState } from 'react';
import { GetFederationEventsToConnect } from '../services/Clubs';

export default function UseParentEvents(clubId) {

    const [parentEvents, setParentEvents] = useState({ isLoading: true, result: false });

    const refresh = () => {
        if (!clubId) {
            return;
        }

        setParentEvents({ isLoading: true });

        GetFederationEventsToConnect(clubId).then((result) => {
            setParentEvents({ result: result, isLoading: false });
        });
    }

    useEffect(() => {
        refresh();
    }, [clubId]);

    return [parentEvents, refresh];
}