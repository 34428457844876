import React from "react";

/* Components */
import Header from "../components/Header";
import ContactSupport from "../components/ContactSupport";

/* Styling */
import "react-datepicker/dist/react-datepicker.css";
import "../styles/card.css";
import "../styles/button.css";

export default function HowToEvent() {
    return (
        <>
            <Header />

            <div className="container">
                <div className="mt-4 mb-4">
                    <div className="title header h2">How to create an event</div>
                </div>
                <div>
                    <h6 className="title mt-5">Working with events</h6>
                    <p>
                        Events are a powerful way to group streams. If you're running a tournament, invitational or otherwise, you can create events and associate streams with the event. This will make it much easier for your customers to find the event. You can easily share the event in social
                        media or newsletters.
                    </p>
                    <div style={{ position: "relative", paddingTop: "66.66666666666666%;" }}>
                        <iframe style={{ border: "none" }} src="https://iframe.mediadelivery.net/embed/11915/a0e0f56f-3155-404a-8986-ee401f099a4d?autoplay=false" loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowFullscreen="true"></iframe>
                    </div>

                    <h6 className="title mt-5">Pay-per-View events and settings</h6>
                    <p>With MATCHi TV studio you can create Pay-per-View events to monetize your tournaments. We'll go through how to set-up a pay-per-view event and how to configure your account to receive payouts.</p>
                    <div style={{
                                position: "relative",
                                paddingTop: "66.66666666666666%;",
                            }}>
                        <iframe style={{ border: "none" }} src="https://iframe.mediadelivery.net/embed/11915/61ecfb3b-90c5-4230-ba66-8154676ad739?autoplay=false" loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                    </div>
                </div>
                <div className="margin-top">
                    <p style={{ marginBottom: 15 }}>
                        See the following manual on how to schedule an Event (<span style={{ fontWeight: "700", marginRight: 1 }}>in the Event section</span>
                        ):{" "}
                        <a target="_blank" title="MATCHi TV Studio Guide" href="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Guide%20v2.pdf">
                            MATCHi TV Studio - Guide
                        </a>
                        .
                    </p>
                </div>
                <div>
                    <iframe src="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Guide%20v2.pdf" title="MATCHi TV Studio Guide" height="600" width="100%"></iframe>
                </div>
                <ContactSupport />
            </div>
        </>
    );
}
