import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StreamsOverview from "./pages/StreamsOverview";
import StreamsSchedule from "./pages/StreamsSchedule";
import StreamsEdit from "./pages/StreamsEdit";
import Cameras from "./pages/Cameras";
import EventsOverview from "./pages/EventsOverview";
import EventsCreate from "./pages/EventsCreate";
import EventsEdit from "./pages/EventsEdit";
import Settings from "./pages/Settings";
import Unauthorized from "./pages/Unauthorized";
import StripeAccountUrl from "./pages/StripeAccountUrl";
import Tutorials from "./pages/Tutorials";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/theme.css";
import HelpAndTutorials from "./pages/HelpAndTutorials";
import HowToEvent from "./pages/HowToEvent";
import HowToSchedule from "./pages/HowToSchedule";
import HowToObs from "./pages/HowToObs";
import HowTechnicalSupport from "./pages/HowTechnicalSupport";
import EventsStreamsOverview from "./pages/EventsStreamsOverview";
import ErrorBoundary from "./components/ErrorBoundary";
import { Spinner } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isLoading, user } = useAuth0();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<Cameras user={user} />} />

          <Route path="/cameras" element={<Cameras user={user} />} />

          <Route
            path="/events/overview"
            element={<EventsOverview user={user} />}
          />

          <Route path="/events/create" element={<EventsCreate user={user} />} />

          <Route path="/events/edit/:id" element={<EventsEdit user={user} />} />

          <Route
            path="/events/streams/:id"
            element={<EventsStreamsOverview user={user} />}
          />

          <Route
            path="/streams/overview"
            element={<StreamsOverview user={user} />}
          />

          <Route
            path="/streams/schedule"
            element={<StreamsSchedule user={user} />}
          />

          <Route
            path="/streams/edit/:externalId"
            element={<StreamsEdit user={user} />}
          />

          <Route path="/settings" element={<Settings />} />

          <Route path="/stripeUrl" element={<StripeAccountUrl />} />

          <Route path="/helpandtutorials/event" element={<HowToEvent />} />

          <Route
            path="/helpandtutorials/schedule"
            element={<HowToSchedule />}
          />

          <Route path="/helpandtutorials/obs" element={<HowToObs />} />

          <Route
            path="/helpandtutorials/technical"
            element={<HowTechnicalSupport />}
          />

          <Route path="/unauthorized" element={<Unauthorized />} />

          <Route path="/tutorials" element={<Tutorials />} />

          <Route path="/helpandtutorials" element={<HelpAndTutorials />} />
        </Routes>
      </ErrorBoundary>
    </Router>
  );
}
export default App;
