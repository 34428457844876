import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmModal({ buttonSize="sm", buttonText, buttonVariant="primary", modalTitle, modalBodyText, modalCancelText="Cancel", modalConfirmText="OK", modalConfirmButtonVariant="primary", action }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button size={buttonSize} variant={buttonVariant} onClick={handleShow}>
                {buttonText}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBodyText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {modalCancelText}
                    </Button>
                    <Button variant={modalConfirmButtonVariant} onClick={() => { action(); handleClose(); }}>
                        {modalConfirmText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ConfirmModal;