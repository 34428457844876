import React, { useEffect, useState } from 'react';
import { GetEvent } from '../services/Clubs';

export default function UseEvent({ id }) {

    const [event, setEvent] = useState({ isLoading: false, result: false });

    const refresh = () => {
        setEvent({ isLoading: true });

        GetEvent(id).then((result) => {
            setEvent({ result: result, isLoading: false });
        });
    };

    useEffect(() => {
        refresh();
    }, []);

    return [event, refresh]
}