import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import useCourts from '../hooks/useCourts';

import "../styles/theme.css";

function MultipleCourtModal({ buttonSize, buttonVariant, buttonText, modalTitle, modalConfirmButtonPrimaryText, setMultipleCourts, setIsMultipleCourts, setSelectedCourt, setSelectedCourtsInModal, selectedCourtsInModal }) {

    const [show, setShow] = useState(false);

    const clickedCourt = (e, item) => {
       if(e.target.checked) {
           setSelectedCourtsInModal([...selectedCourtsInModal, item]);
       } else {
           const newSelectedCourts = Object.assign([], selectedCourtsInModal);
           let indexOfCourtToRemoveInArray = newSelectedCourts.indexOf(item.courtId);
           newSelectedCourts.splice(indexOfCourtToRemoveInArray, 1);
           setSelectedCourtsInModal(newSelectedCourts);
       }
    }

    const save = () => {
        if(selectedCourtsInModal.length > 1){
            setMultipleCourts(selectedCourtsInModal);
            setIsMultipleCourts(true);

        } else if(selectedCourtsInModal.length == 1) {
            setIsMultipleCourts(false);
            setSelectedCourt(selectedCourtsInModal)

        } else if(selectedCourtsInModal.length == 0){ 
            setIsMultipleCourts(false); }

        handleClose();
    }

    const handleClose = () => {   
        setShow(false);}
    const handleShow = () => {
        setShow(true);
        setIsMultipleCourts(false);
    }
    const courts = useCourts();
    useEffect(() => 
    {

    }, [courts.result])

    return (
        <>
            <Button size={buttonSize} variant={buttonVariant} onClick={handleShow}>
                {buttonText}
            </Button>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="body">
                    <p>Available courts in your venue:</p>
                    </div>
                    
                    {courts && courts.result && courts.result.map((item, index) => {
                                        if(index % 2 == 0){
                                            return (<div className="backgroundWhite">
                                                <label value={item.courtId}>
                                                    <input
                                                    type="checkbox" 
                                                    key={index} 
                                                    onChange={(e) => { clickedCourt(e, item) }} /> {item.description}
                                                </label>
                                            </div>)
                                        }
                                        else 
                                        return (<div className="backgroundGrey">
                                                    <label value={item.courtId}>
                                                        <input 
                                                        type="checkbox"  
                                                        key={index}
                                                        onChange={(e) => { clickedCourt(e, item) }}/> {item.description}
                                                    </label>
                                                </div>)
                                                })}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={selectedCourtsInModal.length == 0} onClick={save}>
                        {modalConfirmButtonPrimaryText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MultipleCourtModal;