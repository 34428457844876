import { useEffect, useState } from "react";
import { Alert } from 'react-bootstrap';

function ErrorAlert({heading, bodyText, showError}) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(showError);
    }, [showError])
  
    return (
        <Alert show={show} variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{heading}</Alert.Heading>
          <p>{bodyText}</p>
        </Alert>);
  }

  export default ErrorAlert;