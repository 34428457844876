import React, { useEffect, useState } from 'react';
import { GetCameras } from '../services/Studio';

export default function UseCameras() {

    const [cameras, setCameras] = useState({ isLoading: false, result: false });

    useEffect(() => {
        setCameras({ isLoading: true });

        GetCameras().then((result) => {
            setCameras({ result: result, isLoading: false });
        });
    }, []);

    return [cameras, setCameras];
}