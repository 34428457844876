import axios from 'axios';
import { getTokenSilently } from '../utilities/react-auth0-spa';

export async function getAPIPOSTResponse(baseUrl, route, payload) {
    const accessToken = await getTokenSilently();

    try {
        const config = {
            baseURL: baseUrl,
            method: 'POST',
            headers: { Authorization: "Bearer " + accessToken }
        }

        try {
            if (payload) {
                const result = await axios.create(config).post(route, payload);
                return result.data;
            } else {
                const result = await axios.create(config).post(route);
                return result.data;
            }
            
        }
        catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.status === 404) {
                console.log('Got 404 response from API');
                throw error;
            }
            else {
                console.log(`Generic error in API for route ${route}. we should log this.`);
                console.log(error);
                throw error;
            }
        }
    } catch (error) {
        console.log('There was an error calling the API.');
        console.log(error);
        throw error;
    }
}

export async function getAPIPUTResponse(baseUrl, route, payload) {
    const accessToken = await getTokenSilently();

    try {
        const config = {
            baseURL: baseUrl,
            method: 'PUT',
            headers: accessToken && { Authorization: "Bearer " + accessToken }
        }

        try {
            const result = await axios.create(config).put(route, payload);
            return result.data;
        }
        catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.status === 404) {
                console.log('Got 404 response from API');
                throw error;
            }
            else {
                console.log('Generic error in API. we should log this.');
                console.log(error);
                throw error;
            }
        }
    } catch (error) {
        console.log('There was an error calling the API.');
        console.log(error);
        throw error;
        }
}

export async function getAPIGETResponse(baseUrl, route, isAuthenticationRequired) {

    let accessToken = false;

    if (isAuthenticationRequired) {
        accessToken = await getTokenSilently();
    }

    try {
        const config = {
            baseURL: baseUrl,
            method: 'GET',
            headers: accessToken && { Authorization: "Bearer " + accessToken }
        }

        try {
            const result = await axios.create(config).get(route);
            return result.data;
        }
        catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.status === 404) {
                console.log('Got 404 response from API');
                throw error;
            }
            else {
                console.log('Generic error in API. we should log this.');
                console.log(error);
                throw error;
            }
        }
    } catch (error) {
        console.log('There was an error calling the API.');
        throw error;
    }
}

export async function getAPIDELETEResponse(baseUrl, route) {
    const accessToken = await getTokenSilently();

    try {
        let config = {
            baseURL: baseUrl,
            method: 'DELETE'
        }

        if (accessToken) {
            config = {
                baseURL: baseUrl,
                method: 'DELETE',
                headers: { Authorization: "Bearer " + accessToken }
            }
        }

        try {
            const result = await axios.create(config).delete(route);
            return result.data;
        }
        catch (error) {
            if (typeof (error.response) !== 'undefined' && error.response.status === 404) {
                console.log('Got 404 response from API');
                throw error;
            }
            else {
                console.log('Generic error in stream API. we should log this.');
                console.log(error);
                throw error;
            }
        }
    } catch (error) {
        console.log('There was an error calling the API.');
        throw error;
    }
}