import React, { useEffect, useRef, useReducer, useState } from 'react';
import {
  Container,
  Spinner,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/* Components */
import Header from '../components/Header';
import SuccessModal from '../components/SuccessModal';
import Wysiwyg from '../components/Wysiwyg';

/* Third-party libraries */
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import addDays from 'date-fns/addDays';
import moment from 'moment';

/* Styling */
import 'react-datepicker/dist/react-datepicker.css';

/* Hooks */
import useUser from '../hooks/useUser';
import useParentEvents from '../hooks/useParentEvents';
import useHasClubRole from '../hooks/useHasClubRole';
import {
  reducer,
  SET_VALIDATED,
  SET_ALERT,
  SET_CLUB,
  SET_IS_CREATING_EVENT,
  SET_DISPLAY_SUCCESS_MODAL,
  SET_LOCAL_IMAGE_FILE,
  SET_IS_PAY_PER_VIEW_ENABLED,
  SET_IS_LOADING_STRIPE_ACCOUNT,
  SET_CURRENT_EVENT,
  SET_CLUB_OBJECT,
} from '../hooks/useReducer';

/* Services */
import {
  CreateEvent,
  UploadEventImage,
  GetStripeAccount,
  GetClub,
} from '../services/Clubs';

/* Utilities */
import toBase64 from '../utilities/toBase64';

function EventsCreate({ user }) {
  const currentUser = useUser();
  const hasClubRole = useHasClubRole(user);
  const currentDate = new Date();
  const [alert, setAlert] = useState({
    show: false,
    variant: "",
    heading: "",
    bodytext: "",
  });  
  const navigate = useNavigate();
  const noEndDateCheckBoxRef = useRef(null);
  const [resetEditor, setResetEditor] = useState(false);
  const [clubObject, setClubObject] = useState({
    result: null,
    isLoading: true,
  });

  useEffect(() => {
    if (currentUser.result && currentUser.result.clubId) {
      GetClub(currentUser.result.clubId).then((result) => {
        setClubObject({ result: result, isLoading: false });
      });
    }
  }, [currentUser.isLoading]);

  const currentEventInitialState = {
    eventName: '',
    description: '',
    startDate: setHours(
      setMinutes(new Date(), currentDate.getMinutes()),
      currentDate.getHours()
    ),
    endDate: setHours(
      setMinutes(addDays(new Date(), 1), currentDate.getMinutes()),
      currentDate.getHours()
    ),
    parentEventId: null,
    clubId: null,
    isChildClubAllowedToConnect: false,
  };

  const initialState = {
    currentEvent: currentEventInitialState,
    validated: false,
    alert: {
      show: false,
      variant: 'success',
      heading: '',
      bodytext: '',
    },
    club: null,
    isCreatingEvent: false,
    displaySuccessModal: false,
    localImageFile: null,
    isPayPerViewEnabled: false,
    isLoadingStripeAccount: true,
    parentEvents: useParentEvents(currentUser?.result?.clubId),
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const imageInput = useRef(null);
  const [parentEvents, setParentEvents] = useParentEvents(
    currentUser?.result?.clubId
  );

  useEffect(() => {
    if (currentUser.result && currentUser.result.clubId) {
      dispatch({ type: SET_CLUB, payload: currentUser.result.clubId });
      GetClub(currentUser.result.clubId).then((result) => {
        dispatch({ type: SET_CLUB_OBJECT, payload: result });
      });
    }
  }, [currentUser.isLoading]);

  const filterTime = (time, referenceTime, minDurationInMinutes) => {
    const selectedTime = new Date(time);
    const referenceDateTime = new Date(referenceTime);
    const minValidEndTime = new Date(referenceDateTime);
    minValidEndTime.setMinutes(minValidEndTime.getMinutes() + minDurationInMinutes);
    return selectedTime >= minValidEndTime;
  };

  const handleStartDateChange = (date, endDate) => {
    if (endDate && date > endDate ) {
      const updatedEndDate = new Date(date);
      updatedEndDate.setDate(updatedEndDate.getDate() + 1);
      dispatch({
        type: SET_CURRENT_EVENT,
        payload: {
          ...state.currentEvent,
          startDate: date,
          endDate: updatedEndDate,
        },
      });
    }
    else {
      dispatch({
        type: SET_CURRENT_EVENT,
        payload: {
          ...state.currentEvent,
          startDate: date,
        },
      });
    }
  };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        dispatch({ type: SET_VALIDATED, payload: true });
        event.stopPropagation();
      } else if (state.currentEvent.endDate !== null && state.currentEvent.startDate > state.currentEvent.endDate) 
        {
        dispatch({ type: SET_IS_CREATING_EVENT, payload: false });
        setAlert({
          show: true,
          heading: 'Invalid Time',
          bodytext: 'End time cannot be earlier than start time.',
          variant: 'danger',
        });
      } else {
        dispatch({ type: SET_IS_CREATING_EVENT, payload: true });
        CreateEvent({
          ...state.currentEvent,
          endDate:
            state.currentEvent.endDate !== null
              ? moment.utc(state.currentEvent.endDate).format()
              : null,
          startDate: moment.utc(state.currentEvent.startDate).format(),
          clubId: state.club,
        })
          .then((response) => {
            if (state.localImageFile) {
              toBase64(state.localImageFile).then((imageDataString) => {
                UploadEventImage(response, imageDataString).then((result) => {
                  dispatch({ type: SET_DISPLAY_SUCCESS_MODAL, payload: true });
                });
              });
            } else {
              dispatch({ type: SET_DISPLAY_SUCCESS_MODAL, payload: true });
            }
          })
          .catch((error) => {
            console.error(error);
            dispatch({ type: SET_IS_CREATING_EVENT, payload: false });
            dispatch({
              type: SET_ALERT,
              payload: {
                ...state.alert,
                show: true,
                heading: 'Could not create event',
                variant: 'warning',
                bodytext: error.message,
              },
            });
          })
          .finally(() => {
            dispatch({ type: SET_IS_CREATING_EVENT, payload: false });
            dispatch({ type: SET_DISPLAY_SUCCESS_MODAL, payload: true });          
          });
      }
  };

  useEffect(() => {
    if (!state.displaySuccessModal) {
      dispatch({
        type: SET_CURRENT_EVENT,
        payload: currentEventInitialState,
      });
      dispatch({ type: SET_LOCAL_IMAGE_FILE, payload: null });
    }
  }, [state.displaySuccessModal]);

  useEffect(() => {
    const fetchStripeAccount = async () => {
      const stripeAccountResponse = await GetStripeAccount();
      if (stripeAccountResponse) {
        const stripeAccount = JSON.parse(stripeAccountResponse);
        if (stripeAccount) {
          if (stripeAccount.payouts_enabled) {
            dispatch({ type: SET_IS_PAY_PER_VIEW_ENABLED, payload: true });
          }
        }
      }
      dispatch({ type: SET_IS_LOADING_STRIPE_ACCOUNT, payload: false });
    };
    fetchStripeAccount();
  }, []);

  if (!hasClubRole) {
    navigate('/unauthorized');
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className='mt-4 mb-2'>
            <Alert
                show={alert.show}
                variant={alert.variant}
                onClose={() => setAlert({ ...alert, show: false })}
                dismissible
              >
              <Alert.Heading>{alert.heading}</Alert.Heading>
              <p>{alert.bodytext}</p>
            </Alert>
            <h3 className='title'>Create event</h3>
            <Form
              noValidate
              validated={state.validated}
              onSubmit={handleSubmit}
            >
              <Form.Group controlId='formEventName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type='text'
                  value={state.currentEvent.eventName}
                  placeholder='Enter event name'
                  onChange={(e) => {
                    dispatch({
                      type: SET_CURRENT_EVENT,
                      payload: {
                        ...state.currentEvent,
                        eventName: e.target.value,
                      },
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId='formEventDescription'>
                <Form.Label>Description</Form.Label>
                <div>
                  <Wysiwyg
                    key={resetEditor}
                    currentEvent={state.currentEvent}
                    onEditorStateChange={(description) =>
                      dispatch({
                        type: SET_CURRENT_EVENT,
                        payload: {
                          ...state.currentEvent,
                          description: description,
                        },
                      })
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.File
                  id='formEventThumbnail'
                  ref={imageInput}
                  accept='image/*'
                  label='Event thumbnail (1920x1080)'
                  onChange={(e) => {
                    dispatch({
                      type: SET_CURRENT_EVENT,
                      payload: {
                        ...state.currentEvent,
                        localImageFile: URL.createObjectURL(e.target.files[0]),
                      },
                    });
                    dispatch({
                      type: SET_LOCAL_IMAGE_FILE,
                      payload: e.target.files[0],
                    });
                  }}
                />
              </Form.Group>
              {state.currentEvent.localImageFile && (
                <>
                  <Form.Group>
                    <Image
                      src={state.currentEvent.localImageFile}
                      className='w-50 rounded'
                    />
                  </Form.Group>
                  <Form.Group>
                    <Button
                      variant='danger'
                      size='sm'
                      onClick={() => {
                        dispatch({
                          type: SET_CURRENT_EVENT,
                          payload: {
                            ...state.initialState,
                            localImageFile: null,
                          },
                        });
                        imageInput.current.value = '';
                      }}
                    >
                      Remove image
                    </Button>
                  </Form.Group>
                </>
              )}
              <Form.Group controlId='formEventStart'>
                <Form.Label>Event start</Form.Label>
                <DatePicker
                  className='form-control'
                  wrapperClassName='d-block'
                  selected={state.currentEvent.startDate}
                  onChange={(date) => handleStartDateChange(date, state.currentEvent.endDate)}
                  showTimeSelect
                  minDate={addDays(new Date(), 0)}
                  filterTime={(time) => filterTime(time, new Date(), 0)}
                  timeIntervals={5}
                  timeFormat='HH:mm'
                  dateFormat='yyyy-MM-dd HH:mm'
                />
              </Form.Group>
              <Form.Group controlId='formEventEnd'>
                <Form.Label>Event end</Form.Label>
                <DatePicker
                  disabled={state.currentEvent.endDate === null}
                  className='form-control'
                  wrapperClassName='d-block'
                  selected={state.currentEvent.endDate}
                  onChange={(date) => {
                    dispatch({
                      type: SET_CURRENT_EVENT,
                      payload: {
                        ...state.currentEvent,
                        endDate: date,
                      },
                    });
                    setAlert({
                      ...alert,
                      show: false
                    });
                  }}
                  showTimeSelect
                  minDate={state.currentEvent.startDate}
                  filterTime={(time) => filterTime(time, state.currentEvent.startDate, 5)}
                  timeIntervals={5}
                  timeFormat='HH:mm'
                  dateFormat='yyyy-MM-dd HH:mm'
                />
              </Form.Group>
              <Form.Group controlId='formEventHasNoEndDate'>
                <Form.Check
                  type='checkbox'
                  label='Event has no end date'
                  ref={noEndDateCheckBoxRef}
                  checked={state.currentEvent.endDate === null}
                  onChange={(e) => {
                    dispatch({
                      type: SET_CURRENT_EVENT,
                      payload: {
                        ...state.currentEvent,
                        endDate:
                          state.currentEvent.endDate === null
                            ? state.currentEvent.startDate
                            : null,
                      },
                    });
                  }}
                />
                <Form.Text className='text-muted'>
                  Check this if the event will be on-going throughout the year
                  or longer
                </Form.Text>
              </Form.Group>
              {clubObject &&
                clubObject.result &&
                clubObject.result.parentClubId !== null && (
                  <Form.Group controlId='formParentEvent'>
                    <Form.Label>Federation event</Form.Label>
                    <Form.Control
                      as='select'
                      value={state.currentEvent.parentEventId ?? 'NO_EVENT'}
                      onChange={(e) =>
                        dispatch({
                          type: SET_CURRENT_EVENT,
                          payload: {
                            ...state.currentEvent,
                            parentEventId:
                              e.target.value === 'NO_EVENT'
                                ? null
                                : e.target.value,
                          },
                        })
                      }
                    >
                      {(parentEvents?.result || []).map((item, index) => {
                        return (
                          <option key={index} value={item.eventId}>
                            {item.eventName}
                          </option>
                        );
                      })}
                      <option value={'NO_EVENT'}>
                        No federation event selected
                      </option>
                    </Form.Control>
                  </Form.Group>
                )}

              {clubObject &&
                clubObject.result &&
                clubObject.result.clubType === 'Federation' && (
                  <Form.Group controlId='formEventIsChildClubAllowedToConnectCheck'>
                    <Form.Check
                      type='checkbox'
                      label='Are clubs allowed to connect events?'
                      checked={state.currentEvent.isChildClubAllowedToConnect}
                      onChange={(e) => {
                        dispatch({
                          type: SET_CURRENT_EVENT,
                          payload: {
                            ...state.currentEvent,
                            isChildClubAllowedToConnect:
                              e.currentTarget.checked,
                          },
                        });
                      }}
                    />
                    <Form.Text className='text-muted'>
                      Check this if you want clubs in your federation to be able
                      to connect events to this event.
                    </Form.Text>
                  </Form.Group>
                )}

              {!state.isLoadingStripeAccount && (
                <>
                  <Form.Group controlId='formEventPayPerViewCheck'>
                    <Form.Check
                      disabled={!state.isPayPerViewEnabled}
                      type='checkbox'
                      label='This is a Pay-per-View event'
                      checked={state.currentEvent.isPayPerViewEvent}
                      onChange={(e) => {
                        dispatch({
                          type: SET_CURRENT_EVENT,
                          payload: {
                            ...state.currentEvent,
                            isPayPerViewEvent: e.currentTarget.checked,
                          },
                        });
                      }}
                    />
                    {state.isPayPerViewEnabled ? (
                      <Form.Text className='text-muted'>
                        Check this if the event is a Pay-per-View event. This
                        will require the user to purchase access to the streams
                        associated with this event.
                      </Form.Text>
                    ) : (
                      <Form.Text className='text-danger'>
                        You need to set-up your payout account in Settings first
                        to enable Pay-per-View events.
                      </Form.Text>
                    )}
                  </Form.Group>
                  {state.currentEvent.isPayPerViewEvent && (
                    <Form.Group>
                      <Form.Group controlId='formEventName'>
                        <Form.Label>Price (€)</Form.Label>
                        <Form.Control
                          required
                          type='number'
                          placeholder='Pay-per-View price in €'
                          min='1'
                          step='any'
                          onChange={(e) => {
                            dispatch({
                              type: SET_CURRENT_EVENT,
                              payload: {
                                ...state.currentEvent,
                                price: parseFloat(e.target.value) * 100,
                              },
                            });
                          }}
                        />
                      </Form.Group>
                    </Form.Group>
                  )}
                </>
              )}
              <Button
                disabled={state.isCreatingEvent}
                className='mt-2'
                variant='primary'
                type='submit'
              >
                {state.isCreatingEvent && state.validated && (
                  <>
                    <Spinner
                      className='mr-1 mb-1'
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                    Just a second...
                  </>
                )}
                {!state.isCreatingEvent && <>Create</>}
              </Button>
            </Form>
          </Col>
        </Row>
        <SuccessModal
          showModal={state.displaySuccessModal}
          modalTitle='Event created!'
          modalBodyText={`${state.currentEvent.eventName} was successfully created.`}
          modalConfirmButtonPrimaryText='To overview'
          modalConfirmButtonSecondaryText='Create another event'
          closeModalPrimaryAction={() => {
            navigate('/events/overview');
            dispatch({ type: SET_DISPLAY_SUCCESS_MODAL, payload: false });
          }}
          closeModalSecondaryAction={() => {
            dispatch({
              type: SET_CURRENT_EVENT,
              payload: currentEventInitialState,
            });
            dispatch({ type: SET_IS_CREATING_EVENT, payload: false });
            imageInput.current.value = '';
            setResetEditor((prevState) => !prevState);
            dispatch({
              type: SET_DISPLAY_SUCCESS_MODAL,
              payload: false,
            });
          }}
        />
      </Container>
    </>
  );
}

export default EventsCreate;
