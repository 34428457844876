import React from "react";

import { useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import useUser from "../hooks/useUser";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as StreamIcon } from "../assets/icons/ico_stream.svg";
import { ReactComponent as EventIcon } from "../assets/icons/ico_event.svg";
import { ReactComponent as CameraIcon } from "../assets/icons/ico_camera_white.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/ico_settings.svg";
import { ReactComponent as LogoIcon } from "../assets/icons/ico_logo.svg";

import { ReactComponent as InfoIcon } from "../assets/icons/ico_info.svg";
import { useEffect, useState } from "react";
import { GetClub } from "../services/Clubs";

function Header() {
  const { logout, loginWithRedirect } = useAuth0();
  const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URL;
  const location = useLocation();
  const handleLogout = () => {
    logout({ returnTo: redirectUri, federated: true });
    loginWithRedirect({ redirectUri, prompt: "login" });
  };
  const user = useUser();

  const [club, setClub] = useState({ result: null, isLoading: true });

  useEffect(() => {
    if (user.result) {
      GetClub(user.result.clubId).then((result) => {
        setClub({ result: result, isLoading: false });
      });
    }
  }, [user.result]);

  return (
    <Navbar bg="dark" variant="dark" expand="md" sticky="top">
      <div className="container">
        <Navbar.Brand className="navbar-brand">
          <Link to="/">
            <LogoIcon style={{ height: "16px", width: "168px" }} />
            <br />
            {
              <Navbar.Text
                title={club?.result?.name ?? "\u00A0"}
                className="text-light"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  maxWidth: "168px",
                  maxHeight: "32px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {club?.result?.name ?? "\u00A0"}
              </Navbar.Text>
            }
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto ml-auto text-light">
            <Link
              className="nav-link text-light d-flex align-items-center navigation"
              to="/cameras"
              style={{
                borderBottom:
                  location.pathname === "/cameras"
                    ? "8px solid white"
                    : "2px solid transparent",
              }}
            >
              <CameraIcon className="mr-2" /> Cameras
            </Link>
            <Link
              className="nav-link text-light d-flex align-items-center navigation"
              to="/streams/overview"
              style={{
                borderBottom:
                  location.pathname === "/streams/overview"
                    ? "8px solid white"
                    : "2px solid transparent",
              }}
            >
              <StreamIcon className="mr-2" /> Streams
            </Link>
            <Link
              className="nav-link text-light d-flex align-items-center navigation"
              to="/events/overview"
              style={{
                borderBottom:
                  location.pathname === "/events/overview"
                    ? "8px solid white"
                    : "2px solid transparent",
              }}
            >
              <EventIcon className="mr-2" /> Events
            </Link>
            <Link
              className="nav-link text-light d-flex align-items-center navigation"
              to="/settings"
              style={{
                borderBottom:
                  location.pathname === "/settings"
                    ? "8px solid white"
                    : "2px solid transparent",
              }}
            >
              <SettingsIcon className="mr-2" /> Settings
            </Link>
            <Link
              className="nav-link text-light d-flex align-items-center navigation"
              to="/helpandtutorials"
              style={{
                borderBottom:
                  location.pathname === "/helpandtutorials"
                    ? "8px solid white"
                    : "2px solid transparent",
              }}
            >
              <InfoIcon className="mr-2" /> Help and tutorials
            </Link>
          </Nav>
          <Nav className="nav-user">
            <Nav.Link
              className="text-light rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: "40px", height: "40px", border: "2px solid" }}
            >
              {user &&
                user.result &&
                user.result.firstName?.substring(0, 1) +
                  user.result.lastName?.substring(0, 1)}
            </Nav.Link>
            <Nav.Link
              className="text-light"
              style={{ fontSize: "18px" }}
              onClick={() => handleLogout()}
            >
              Log out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Header;
