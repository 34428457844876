import React, { useEffect, useState } from 'react';
import { GetUser } from '../services/Users';

export default function UseUser() {

    const [user, setUser] = useState({ isLoading: false, result: false });

    useEffect(() => {
        setUser({ isLoading: true });

        GetUser().then((result) => {
            setUser({ result: result, isLoading: false });
        });
    }, []);

    return user;
}