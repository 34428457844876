import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

export default function Tutorials() {
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md">
        <LinkContainer to="/">
          <Navbar.Brand className="navbar-brand">MATCHi TV studio</Navbar.Brand>
        </LinkContainer>
      </Navbar>
      <Container>
        <Row className="mt-4 mb-2">
          <Col>
            <h3 className="title">MATCHi TV studio tutorials</h3>
            <p>Here are video tutorials on how to use MATCHi TV studio.</p>
            <p>
              To get started with MATCHi TV studio you need a MATCHi TV studio
              account. If you're a club owner and haven't received one yet,
              please reach out to
              <a href="mailto:hello@matchi.tv">hello@matchi.tv</a> and we'll get
              you set-up.
            </p>

            <h6 className="title mt-5">Introduction</h6>
            <p>
              A basic overview of how MATCHi TV studio works and what you can
              expect to find in the tool.
            </p>
            <div
              style={{
                position: "relative",
                paddingTop: "66.66666666666666%;",
              }}
            >
              <iframe
                style={{ border: "none" }}
                src="https://iframe.mediadelivery.net/embed/11915/77de9c53-04a7-46ae-89d3-e40ea9b03029?autoplay=false"
                loading="lazy"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
              ></iframe>
            </div>

            <h6 className="title mt-5">Scheduling streams</h6>
            <p>
              We go through the primary functionality of MATCHi TV studio, which
              is how you can schedule one or many streams for your venue.
            </p>
            <div
              style={{
                position: "relative",
                paddingTop: "66.66666666666666%;",
              }}
            >
              <iframe
                style={{ border: "none" }}
                src="https://iframe.mediadelivery.net/embed/11915/dedc9dad-2e7a-4fc3-9151-9fcd6dde70bc?autoplay=false"
                loading="lazy"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
              ></iframe>
            </div>

            <h6 className="title mt-5">Working with events</h6>
            <p>
              Events are a powerful way to group streams. If you're running a
              tournament, invitational or otherwise, you can create events and
              associate streams with the event. This will make it much easier
              for your customers to find the event. You can easily share the
              event in social media or newsletters.
            </p>
            <div
              style={{
                position: "relative",
                paddingTop: "66.66666666666666%;",
              }}
            >
              <iframe
                style={{ border: "none" }}
                src="https://iframe.mediadelivery.net/embed/11915/a0e0f56f-3155-404a-8986-ee401f099a4d?autoplay=false"
                loading="lazy"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
              ></iframe>
            </div>

            <h6 className="title mt-5">Pay-per-View events and settings</h6>
            <p>
              With MATCHi TV studio you can create Pay-per-View events to
              monetize your tournaments. We'll go through how to set-up a
              pay-per-view event and how to configure your account to receive
              payouts.
            </p>
            <div
              style={{
                position: "relative",
                paddingTop: "66.66666666666666%;",
              }}
            >
              <iframe
                style={{ border: "none" }}
                src="https://iframe.mediadelivery.net/embed/11915/61ecfb3b-90c5-4230-ba66-8154676ad739?autoplay=false"
                loading="lazy"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
              ></iframe>
            </div>

            <h6 className="title mt-5">Streaming with OBS Studio</h6>
            <p>
              With OBS Studio you can create more advanced streams together with
              matchi.tv. In this tutorial we'll go through OBS Studio and how it
              integrates with matchi.tv. You'll learn how to set-up OBS Studio,
              stream with one of the matchi.tv cameras, add graphics and
              commentators to the stream.
            </p>
            <div
              style={{
                position: "relative",
                paddingTop: "66.66666666666666%;",
              }}
            >
              <iframe
                style={{ border: "none" }}
                src="https://iframe.mediadelivery.net/embed/11915/807724a5-ac1b-4a01-8eb0-a6997e9a51ca?autoplay=false"
                loading="lazy"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowfullscreen="true"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
