import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

/* Components */
import Header from "../components/Header";

/* Third-party libraries */
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";

/* Hooks */
import useCameras from "../hooks/useCameras";
import useHasClubRole from "../hooks/useHasClubRole";

import { ReactComponent as CameraIcon } from "../assets/icons/ico_camera.svg";
import { ReactComponent as PlayIcon } from "../assets/icons/ico_play.svg";
import { ReactComponent as ErrorIcon } from "../assets/icons/ico_error.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/ico_check.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/ico_close.svg";
import { ReactComponent as MoreIcon } from "../assets/icons/ico_more.svg";
import { ToggleCamera } from "../services/Studio";

export default function Cameras({ user }) {
  const [cameras, setCameras] = useCameras();
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState();
  const navigate = useNavigate();
  const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URL;

  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const hasClubRole = useHasClubRole(user);

  if (!isAuthenticated) {
    loginWithRedirect({ redirectUri, prompt: "login" });
  } else if (!hasClubRole) {
    navigate("/unauthorized");
  }

  const getCameraStatus = (camera) => {
    if (camera) {
      if (camera.isLive) {
        return (
          <span className="d-flex flex-row align-items-center">
            <span
              className="d-flex"
              style={{
                borderRadius: "5px",
                width: "10px",
                height: "10px",
                backgroundColor: "#1E9A52",
              }}
            />
            <span className="ml-1">Live</span>
          </span>
        );
      } else if (camera.isActive && !camera.isDown) {
        return (
          <span className="d-flex flex-row align-items-center">
            <span
              className="d-flex"
              style={{
                borderRadius: "5px",
                width: "10px",
                height: "10px",
                borderColor: "#22AD5C",
                borderWidth: "2px",
                borderStyle: "solid",
              }}
            />
            <span className="ml-1">Enabled</span>
          </span>
        );
      } else if (!camera.isActive && !camera.isDown) {
        return (
          <span className="d-flex flex-row align-items-center">
            <span
              className="d-flex"
              style={{
                borderRadius: "5px",
                width: "10px",
                height: "10px",
                backgroundColor: "#D64045",
              }}
            />
            <span className="ml-1">Disabled</span>
          </span>
        );
      } else if (camera.isDown) {
        return (
          <span className="d-flex flex-row align-items-center">
            <span
              className="d-flex"
              style={{
                borderRadius: "5px",
                width: "10px",
                height: "10px",
                borderColor: "#D64045",
                borderWidth: "2px",
                borderStyle: "solid",
              }}
            />
            <span className="ml-1">Down</span>
          </span>
        );
      }
    }

    return null;
  };

  const getNumberOfCamerasThatHaveStatusStreaming = (cameras) => {
    if (cameras && Array.isArray(cameras)) {
      return cameras.filter((camera) => camera.isLive).length;
    }

    return 0;
  };

  const getStatusForCameras = (cameras) => {
    if (cameras && Array.isArray(cameras)) {
      if (cameras.length === 0) {
        return "No cameras found";
      }

      const numberOfBlockedCameras = cameras.filter(
        (camera) => camera.isMaintenance || !camera.isActive || camera.isDown
      ).length;

      return numberOfBlockedCameras > 0
        ? `${numberOfBlockedCameras}/${cameras.length}`
        : "All active";
    }

    return "No cameras found";
  };

  const getStatusIconForCameras = (cameras) => {
    if (cameras && Array.isArray(cameras)) {
      return cameras.filter((camera) => camera.isDown || !camera.isActive)
        .length > 0 ? (
        <ErrorIcon />
      ) : (
        <CheckIcon />
      );
    }

    return <CheckIcon />;
  };

  const closeModal = () => {
    setDisplayModal(false);
    setSelectedCamera();
  };

  const showModal = (camera) => {
    setDisplayModal(true);
    setSelectedCamera(camera);
  };

  const handleToggleCameraStatus = async (camera) => {
    const updatedCamera = { ...camera, isActive: !camera.isActive };
    setSelectedCamera(updatedCamera);
    const updatedCameras = cameras.result.map((c) =>
      c.cameraId === updatedCamera.cameraId ? updatedCamera : c
    );
    setCameras({ result: updatedCameras, isLoading: false, error: null });
    await ToggleCamera(camera.cameraId, !camera.isActive);
  };

  return (
    <>
      <Header />
      <Container fluid="md">
        <Row className="mt-4 mb-2">
          <Col>
            <h3 className="title">Cameras</h3>
          </Col>
        </Row>
        {!cameras.isLoading && (
          <Row className="mb-4">
            <Col sm>
              <CameraInformationCard
                title="Cameras"
                value={cameras.result?.length}
              >
                <CameraIcon style={{ height: "24px", width: "24px" }} />
              </CameraInformationCard>
            </Col>
            <Col sm>
              <CameraInformationCard
                title="Live now"
                value={`${getNumberOfCamerasThatHaveStatusStreaming(
                  cameras.result
                )}/${cameras.result?.length}`}
              >
                <PlayIcon style={{ height: "24px", width: "24px" }} />
              </CameraInformationCard>
            </Col>
            <Col sm>
              <CameraInformationCard
                title="Alert"
                value={getStatusForCameras(cameras.result)}
              >
                {getStatusIconForCameras(cameras.result)}
              </CameraInformationCard>
            </Col>
          </Row>
        )}
        <Row>
          {cameras.isLoading && (
            <Col className="d-flex justify-content-center align-items-center mt-5">
              <Spinner animation="border" />
            </Col>
          )}

          {!cameras.isLoading && (
            <Col>
              {cameras && cameras.result && cameras.result.length === 0 && (
                <p>No cameras added</p>
              )}
              {cameras && cameras.result && cameras.result.length > 0 && (
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Name &amp; Id</th>
                      <th>Court</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cameras.result.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <p style={{ fontWeight: "600", fontSize: "16px" }}>
                              {item.liveEventName}
                            </p>
                            {item.cameraId}
                          </td>
                          <td>{item.courtName}</td>
                          <td>{getCameraStatus(item)}</td>
                          <td className="">
                            <button
                              style={{
                                marginTop: "8px",
                                background: "none",
                                border: "0",
                              }}
                              onClick={() => showModal(item)}
                            >
                              <MoreIcon />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          )}
        </Row>
      </Container>
      {displayModal && selectedCamera && (
        <>
          <div
            onClick={() => {
              closeModal();
            }}
            className="overlay position-fixed"
            style={{
              zIndex: 11,
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
          <div
            className="position-fixed"
            style={{
              zIndex: 11,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "396px",
              background: "#FAFAFA",
              borderRadius: "10px",
              padding: "0px",
            }}
          >
            <div
              className="d-flex header justify-content-center align-items-center"
              style={{
                position: "relative",
                height: "80px",
                background: "white",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  padding: "24px",
                  margin: "8px",
                }}
              >
                {selectedCamera.name}
              </span>
              <button
                onClick={() => {
                  closeModal();
                }}
                style={{
                  border: "0",
                  background: "none",
                  position: "absolute",
                  left: "16px",
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="d-flex flex-column" style={{ padding: "24px" }}>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span style={{ fontSize: "16px", fontWeight: "500" }}>
                  Status
                </span>

                <div
                  className="d-flex flex-row align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleToggleCameraStatus(selectedCamera);
                  }}
                >
                  {!selectedCamera.isDown && (
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        marginRight: "8px",
                      }}
                    >
                      {selectedCamera.isActive ? "Enabled" : "Disabled"}
                    </span>
                  )}
                  {/* Toggle switch */}
                  {!selectedCamera.isDown && (
                    <div
                      className={`d-flex align-items-center`}
                      style={{
                        position: "relative",
                        transition: "all 0.3s",
                        width: "36px",
                        height: "22px",
                        borderRadius: "11px",
                        background: `${
                          selectedCamera.isActive ? "#22AD5C" : "#D64045"
                        }`,
                        paddingRight: "3px",
                        paddingLeft: "3px",
                      }}
                    >
                      <div
                        style={{
                          transition: "all 0.3s",
                          position: "absolute",
                          left: `${selectedCamera.isActive ? "16px" : "3px"}`,
                          width: "16px",
                          height: "16px",
                          borderRadius: "8px",
                          background: "white",
                        }}
                      />
                    </div>
                  )}
                </div>

                {selectedCamera.isDown && (
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      marginRight: "8px",
                    }}
                  >
                    Camera is down
                  </span>
                )}
              </div>

              {selectedCamera.isActive && (
                <span style={{ marginTop: "24px" }}>
                  Make camera unavailable for future streams by switching the
                  status. Any ongoing stream will be completed.
                </span>
              )}

              {!selectedCamera.isActive && !selectedCamera.isDown && (
                <span style={{ marginTop: "24px" }}>
                  Make camera available for streams by switching the status.
                </span>
              )}

              {selectedCamera.isDown && (
                <div style={{ marginTop: "24px" }}>
                  <span>
                    It seems like your camera is down. <br></br>
                  </span>
                  <a href="/helpandtutorials/technical"> Click here </a>
                  <span> for more information.</span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

const CameraInformationCard = ({ title, value, children }) => {
  return (
    <div
      className="d-flex flex-row border justify-content-between align-items-center"
      style={{
        borderRadius: "10px",
        paddingTop: "16px",
        paddingLeft: "24px",
        paddingBottom: "16px",
        paddingRight: "24px",
      }}
    >
      <div className="d-flex flex-column">
        <p
          className="pb-1"
          style={{ color: "#8C8C8C", fontWeight: "600", fontSize: "14px" }}
        >
          {title}
        </p>
        <h3 style={{ fontWeight: "600", fontSize: "24px" }}>{value}</h3>
      </div>
      <span>{children}</span>
    </div>
  );
};
