import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

function MultipleSuccessModal({ isMultipleCourts, multipleCourts, isSchedulingStream, scheduling, responseMessages, submitableList, showModal, closeModalPrimaryAction, closeModalSecondaryAction, modalTitle, modalText, modalConfirmButtonPrimaryText, modalConfirmButtonSecondaryText }) {

    const [show, setShow] = useState(false);
    const handleClosePrimary = () => { setShow(false); closeModalPrimaryAction(); }
    const handleCloseSecondary = () => { setShow(false); closeModalSecondaryAction(); }

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    return (
        <Modal show={show} onHide={() => handleCloseSecondary()}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {submitableList.map((item, index) => {
                    const submitableMulti = multipleCourts[index];
                    const message = responseMessages.find((message) => {
                        return message.court == item.court;
                    })
                    if(isMultipleCourts){
                        return (<div>
                            <p>{submitableMulti.description} - <span className={`${message?.message == "Stream successfully scheduled" ? "success" : "error"} `}>{message?.message}</span>
                            {isSchedulingStream && message == null &&
                        <Spinner
                            className="mr-1 mb-1"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        </p>
                        </div>)
                    }
                    else{
                        return (<div>
                            <p>{item.description} - <span className={`${message?.message == "Stream successfully scheduled" ? "success" : "error"} `}>{message?.message}</span>
                            {isSchedulingStream && message == null &&
                        <Spinner
                            className="mr-1 mb-1"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        </p>
                        </div>)
                    }
                    
                })} 
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { handleCloseSecondary(); }}>
                    {modalConfirmButtonSecondaryText}
                </Button>
                <Button variant="primary" onClick={() => { handleClosePrimary(); }}>
                    {modalConfirmButtonPrimaryText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MultipleSuccessModal;