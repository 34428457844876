import React, { useState } from "react";

/* Components */
import Header from "../components/Header";
import ContactSupport from "../components/ContactSupport";
import Accordion from "../components/Accordion";

/* Styling */
import "../styles/helpstyles.css";

export default function HowTechnicalSupport() {
  const data = [
    {
      question: "It says “Maintenance!” when I scan the QR-code!",
      answere:
        "Has the camera worked before? Then check cables. Make sure the cables are connected properly, both in the camera and in the PoE-switch. Also, try with a new ethernet cable (cat6) to make sure nothing happened to the cable. First time trying to use the camera? Contact support: +46 760 21 05 29, and we'll activate the camera.",
    },
    {
      question:
        "For how long can I schedule a stream?",
      answere:
        "Our video processor can handle videos up to 15 hours. If you need to stream for longer, schedule multiple streams per day. For example: 05.00-20.00, 20.00-24.00 etc.",
    },
    {
      question: "I want to know more about how to stream with OBS studio.",
      answere:
        "Great! We're happy to help! Just reach out via email or phone and we will arrange a meeting with you where we teach you the fundamentals to make a professional stream.",
    },
    {
      question: "A stream suddenly stopped. What should I do?",
      answere:
        "If you streamed with OBS studio: Schedule a new stream and start streaming again. Remember to update the stream key in OBS studio and hand out the new stream link to the viewers. If you didn't use OBS studio: Make a new stream schedule and choose the option to start the stream directly.",
    },
    {
      question:
        "Streams stopped again! Why does that happen and what can I do about it?",
      answere:
        "Check the internet in your venue. You need at LEAST 5mbit/s upload speed PER CAMERA. You can check your internet speed here: ",
      link: "http://www.bredbandskollen.se/en/",
      sub: "There could be several reasons why a stream stopps prematurley. More often it's the network in the venue that suddenly drops for a short moment, and that makes the camera think the stream is over.",
    },
  ];

  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 mb-4">
          <div className="techcontainer">
            <div className="title header h2">Technical help for the venue</div>

            <div className="accordion">
              {data.map((data) => (
                <div>
                  <Accordion
                    question={data.question}
                    answere={data.answere}
                    link={data.link}
                    sub={data.sub}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <ContactSupport />
      </div>
    </>
  );
}
