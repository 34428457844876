import React, { useEffect, useState } from "react";
/* Components */
import Header from "../components/Header";
import { Container, Spinner, Row, Col, Card } from "react-bootstrap";
import { WarningRounded, CheckRounded } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { GetStripeAccount } from "../services/Clubs";

function Settings() {
  const [stripeAcc, setStripeAcc] = useState();

  const handleGetStripeAccount = async () => {
    const acc = await GetStripeAccount();

    if (acc) {
      setStripeAcc(JSON.parse(acc));
    }
  };

  useEffect(() => {
    handleGetStripeAccount();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Row className="mt-3">
          <Col md={12}>
            <Card>
              <Card.Body>
                <Card.Title>Payout</Card.Title>
                <Card.Body className="pl-0 pr-0">
                  To receive payments from Pay-per-View events you need to
                  configure a bank account where you will receive your payout.
                  Normally, we process payouts within the same business week.
                </Card.Body>
                <Row>
                  {!stripeAcc && (
                    <Col className="d-flex justify-content-center align-items-center pb-3">
                      <Spinner animation="border" />
                    </Col>
                  )}
                  {stripeAcc && stripeAcc.payouts_enabled && (
                    <Col>
                      <div className="alert alert-success" role="alert">
                        <CheckRounded />
                        You have successfully enabled Stripe payouts!
                      </div>
                      <Link to="/stripeUrl" className="text-primary">
                        Manage your payout settings in Stripe
                      </Link>
                    </Col>
                  )}
                  {stripeAcc && !stripeAcc.payouts_enabled && (
                    <Col>
                      <div className="alert alert-danger" role="alert">
                        <WarningRounded />
                        You haven't configured a bank account for payouts yet
                      </div>
                      <Link
                        to="/stripeUrl"
                        target="_blank"
                        className="text-primary"
                      >
                        Set-up bank account for payouts with Stripe
                      </Link>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Settings;
