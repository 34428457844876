import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

export default function Unauthorized() {
  const { logout, loginWithRedirect } = useAuth0();
  const redirectUri = process.env.REACT_APP_AUTH_REDIRECT_URL;

  const handleLoginAgain = () => {
    logout({ returnTo: redirectUri, federated: true });
    loginWithRedirect({ redirectUri, prompt: "login" });
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md">
        <LinkContainer to="/">
          <Navbar.Brand className="navbar-brand">MATCHi TV Studio</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-nav ml-auto"></Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container>
        <Row className="mt-4 mb-2">
          <Col>
            <h3 className="title">Unauthorized</h3>
            <p>
              You're not authorized to use MATCHi TV Studio with the current
              account. Try to{" "}
              <a href="#" onClick={handleLoginAgain}>
                log in again
              </a>{" "}
              with a different account.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
