import React, { useEffect, useState } from 'react';
import { GetStreams } from '../services/Studio';

export default function UseStreams() {

    const [streams, setStreams] = useState({ isLoading: false, result: false });

    useEffect(() => {
        setStreams({ isLoading: true });

        GetStreams().then((result) => {
            setStreams({ result: result, isLoading: false });
        });
    }, []);

    return [streams, setStreams]
}