import React from 'react'
import '../styles/helpstyles.css'

export default function ContactSupport(){
    return(
        <>
        <div className="container">
          <div className="support-card">
            <p>
              <br></br> If you have any questions or in need of additional
              assistance please contact:
              <br></br>
              <a className="mail-support" href="mailto:support@matchi.tv">
                support@matchi.tv
              </a>
              <br></br>
              <p className="phone">Phone: +46 760 21 05 29</p>
            </p>
          </div>
        </div>
        </>
    )
}