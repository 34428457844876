import { useState, useEffect } from "react";

function useHasClubRole(user) {
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    if (user && user["https://padelgo.tv/roles"].includes("club")) {
      setHasRole(true);
    } else {
      setHasRole(false);
    }
  }, [user]);

  return hasRole;
}

export default useHasClubRole;
