import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function HelpCard(props) {
  return (
    <div className="card-container">
      <div className="image-container">
        <img src={props.img} alt="" />
      </div>
      <div className="card-content">
        <div className="card-title">
          <h4>{props.title}</h4>
        </div>
        <div className="card-body">
          <p>{props.body}</p>
        </div>
      </div>
      <div className="btn-container">
        <Link to={props.link}>
          <button>
            Show me
          </button>
        </Link>
      </div>
    </div>
  );
}
