import React, { useEffect, useState } from 'react';
import { GetStream } from '../services/Studio';

export default function UseStream({ externalId }) {

    const [stream, setStream] = useState({ isLoading: false, result: false });

    const refresh = () => {
        setStream({ isLoading: true });

        GetStream(externalId).then((result) => {
            setStream({ result: result, isLoading: false });
        });
    };

    useEffect(() => {
        refresh();
    }, []);

    return [stream, refresh]
}