import React from "react";

/* Components */
import Header from "../components/Header";
import ContactSupport from "../components/ContactSupport";

/* Styling */
import "react-datepicker/dist/react-datepicker.css";
import "../styles/card.css";
import "../styles/button.css";
import "../styles/material-icons.css";

export default function HowToObs() {
    return (
        <>
            <Header />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"></link>
            <div className="container">
                <div className="mt-4 mb-4">
                    <div className="title header h2">How to stream with OBS studio</div>
                    <div className="container">
                        <h5>Quick notes:</h5>

                        <ol>
                            <li>
                                <span className="material-icons">play_circle_outline</span> The stream only starts when you press "Start Streaming" in OBS studio.
                            </li>

                            <li>
                                <span className="material-icons">highlight_off</span> If you press "Stop Streaming" in OBS studio you'll end the stream.
                            </li>
                            <li>
                                <span className="material-icons">priority_high</span> You can't stream again on the same stream if you pressed "Stop Streaming" in OBS.
                            </li>
                        </ol>
                    </div>
                    <div>
                        <h6 className="title mt-5">Streaming with OBS Studio</h6>
                        <p>
                            With OBS Studio you can create more advanced streams together with matchi.tv. In this tutorial we'll go through OBS Studio and how it integrates with matchi.tv. You'll learn how to set-up OBS Studio, stream with one of the matchi.tv cameras, add graphics and commentators
                            to the stream.
                        </p>
                        <div
                            style={{
                                position: "relative",
                                paddingTop: "66.66666666666666%;",
                            }}
                        >
                            <iframe style={{ border: "none" }} src="https://iframe.mediadelivery.net/embed/11915/807724a5-ac1b-4a01-8eb0-a6997e9a51ca?autoplay=false" loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true" />
                        </div>
                        <div className="margin-top">
                            <p style={{ marginBottom: 15 }}>
                                See the following manual on how to set-up OBS Studio on your computer and configure your stream:{" "}
                                <a target="_blank" title="OBS installation and set-up" href="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Stream%20with%20OBS%20v2.pdf">
                                    MATCHi TV Studio - Stream with OBS
                                </a>
                                .
                            </p>
                            <div>
                                <iframe src="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Stream%20with%20OBS%20v2.pdf" title="MATCHi TV Studio stream with OBS" height="600" width="100%"></iframe>
                            </div>
                            <ContactSupport />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
