import { getAPIPOSTResponse } from "./BaseService";
import moment from "moment";

const baseUrl = process.env.REACT_APP_STREAMS_API_BASE_URL;

export async function ScheduleStream(stream) {
  const scheduleStreamDto = {
    liveStream: {
      description: stream.description,
      startDateTime: moment.utc(stream.startDate).format(),
      endDateTime: moment.utc(stream.endDate).format(),
      courtId: parseInt(stream.court),
      eventId: parseInt(stream.eventId),
      channel: stream.channel,
      isPrivate: stream.isPrivate,
      isStudio: stream.streamManually,
    },
  };

  console.log("scheduling stream...");
  console.log("scheduleStreamDto", scheduleStreamDto);

  return getAPIPOSTResponse(baseUrl, "Streams", scheduleStreamDto);
}

export async function GetEventsStreams(eventId, page, take) {
  const mediaRequestDto = {
    eventExtId: eventId,
    stream: true,
    liveStream: true,
    highlight: true,
    video: true,
    upcoming: true,
    page: page,
    take: take,
    sortOrder: 1,
  };

  return getAPIPOSTResponse(baseUrl, "Media/event", mediaRequestDto);
}
