import React from "react";
/* Components */
import Header from "../components/Header";
import ConfirmModal from "../components/ConfirmModal";
import InfoModal from "../components/InfoModal";

/* Third-party libraries */
import { Container, Row, Col, Table, Button, Spinner } from "react-bootstrap";
import moment from "moment";

/* Hooks */
import useEvents from "../hooks/useEvents";
import useUser from "../hooks/useUser";
import useHasClubRole from "../hooks/useHasClubRole";

/* Services */
import { DeleteEvent } from "../services/Clubs";
import { Link, useNavigate } from "react-router-dom";

export default function EventsOverview({ user }) {
  const currentUser = useUser();
  const [events, refresh] = useEvents(currentUser?.result?.clubId);
  const navigate = useNavigate();
  const hasClubRole = useHasClubRole(user);

  if (!hasClubRole) {
    navigate("/unauthorized");
  }

  return (
    <>
      <Header />
      <Container>
        <Row className="mt-4 mb-2">
          <Col>
            <h3 className="title">Events</h3>
          </Col>
          <Col className="d-flex flex-row-reverse">
            <Link to="/events/create">
              <Button variant="outline-primary">Create event</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          {events.isLoading && (
            <Col className="d-flex justify-content-center align-items-center mt-5">
              <Spinner animation="border" />
            </Col>
          )}
          {!events.isLoading && (
            <Col>
              {events && events.result && events.result.length === 0 && (
                <p>No events added</p>
              )}
              {events && events.result && events.result.length > 0 && (
                <Table responsive className="table-overflow-text-rows">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Title</th>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Thumbnail</th>
                      <th>Streams</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.result.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.eventId}</td>
                          <td title={item.eventName}>{item.eventName}</td>
                          <td>
                            {moment
                              .utc(item.startDate)
                              .local()
                              .format("D/M HH:mm")}
                          </td>
                          <td>
                            {item.endDate
                              ? moment
                                  .utc(item.endDate)
                                  .local()
                                  .format("D/M HH:mm")
                              : "no end date"}
                          </td>
                          <td>
                            <img src={item.eventImage} height="60" />
                          </td>
                          <td>
                            <Link
                              to={`/events/streams/${item.eventExtId}`}
                              size="sm"
                              variant="outline-primary"
                            >
                              View ({item.numberOfStreams})
                            </Link>
                          </td>
                          <td>
                            <a
                              href={`${process.env.REACT_APP_PADELGO_WEBSITE_URL}/event/${item.eventExtId}`}
                              target="_blank"
                            >
                              Go to event
                            </a>
                          </td>
                          <Link to={`/events/edit/${item.eventExtId}`}>
                            <td>
                              <Button size="sm" variant="outline-primary">
                                Edit
                              </Button>
                            </td>
                          </Link>
                          <td>
                            {item.numberOfStreams == 0 ? (
                              <ConfirmModal
                                buttonText="Delete"
                                buttonVariant="outline-danger"
                                modalTitle="Delete event"
                                modalBodyText="Are you sure you want to delete the event? This can't be undone!"
                                modalCancelText="Cancel"
                                modalConfirmText="Yes, delete it!"
                                modalConfirmButtonVariant="outline-danger"
                                action={async () => {
                                  await DeleteEvent(item.eventExtId);
                                  refresh();
                                }}
                              ></ConfirmModal>
                            ) : (
                              <InfoModal
                                buttonText="Delete"
                                buttonVariant="outline-danger"
                                modalTitle="Delete event"
                                modalBodyText="The event needs to be empty of streams before it can be deleted."
                                modalConfirmText="Ok"
                                modalConfirmButtonVariant="outline-primary"
                              ></InfoModal>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}
