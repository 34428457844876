import React, { useState } from "react";
import { Modal, Button, Form, Alert, Col } from "react-bootstrap";

function ManualStreamModal({
    buttonSize,
    buttonVariant,
    buttonText,
    modalTitle,
    modalConfirmButtonPrimaryText,
    stream,
}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const copyToClipboard = (e, textToCopy) => {
        e.currentTarget.innerHTML = "Copied!";
        e.currentTarget.blur();
        navigator.clipboard.writeText(textToCopy);

        const copyToClipboard = (e, textToCopy) => {
            e.currentTarget.innerHTML = "Copied!";
            e.currentTarget.blur();
            navigator.clipboard.writeText(textToCopy);

            setTimeout(
                (target) => {
                    target.innerHTML = "Copy";
                },
                2000,
                e.currentTarget
            );
        };

        return (
            <>
                <Button
                    size={buttonSize}
                    variant={buttonVariant}
                    onClick={handleShow}
                >
                    {buttonText}
                </Button>
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            If you want to stream manually with OBS Studio you
                            first need to install OBS Studio on your computer.
                            You can{" "}
                            <a
                                target="_blank"
                                title="Download OBS Studio"
                                href="https://obsproject.com/"
                            >
                                download OBS Studio here
                            </a>{" "}
                            - it's free. This is a software you can use to
                            connect directly to the camera. In OBS Studio you'll
                            add score, commentators, custom ads, etc. <br />
                            <br />
                            See the following manual on how to set-up OBS Studio
                            on your computer and configure your stream:
                            <a
                                target="_blank"
                                title="OBS installation and set-up"
                                href="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Stream%20with%20OBS%20v2.pdf"
                            >
                                OBS Studio installation and stream set-up guide
                            </a>
                            .
                        </p>
                        <p>
                            Use the following settings in your preferred
                            streaming software for the stream.
                        </p>
                        <Alert variant="secondary">
                            <Alert.Heading>Recommended settings</Alert.Heading>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Media Source Input</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                id="mediaSourceInput"
                                                placeholder={`rtsp://live:Padel123!@${stream.cameraIP}?enableaudio=1`}
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(
                                                        e,
                                                        `rtsp://live:Padel123!@${stream.cameraIP}?enableaudio=1`
                                                    );
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Server</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder={`${process.env.REACT_APP_PASSTHROUGH_URL}/passthrough`}
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(
                                                        e,
                                                        `${process.env.REACT_APP_PASSTHROUGH_URL}/passthrough`
                                                    );
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Stream key</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder={stream.externalId}
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(
                                                        e,
                                                        stream.externalId
                                                    );
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Bitrate</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder="5400"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(e, "5400");
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>CPU Usage Preset</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder="Veryfast"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(
                                                        e,
                                                        "Veryfast"
                                                    );
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Base (Canvas) Resolution
                                    </Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder="1920x1080"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(
                                                        e,
                                                        "1920x1080"
                                                    );
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Output (Scaled) Resolution
                                    </Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder="1920x1080"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(
                                                        e,
                                                        "1920x1080"
                                                    );
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Common FPS Values</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder="30"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(e, "30");
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Score URL</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder={`${process.env.REACT_APP_SCORES_API_BASE_URL}/scores/image/streamextid/obs/${stream.externalId}`}
                                                disabled
                                                aria-describedby="scoreHelpBlock"
                                            />
                                            <Form.Text
                                                id="scoreHelpBlock"
                                                muted
                                            >
                                                The score will be blank until it has
                                                been initiated for the stream. Go to
                                                "Manage stream" and activate the scoreboard.
                                            </Form.Text>
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(
                                                        e,
                                                        `${process.env.REACT_APP_SCORES_API_BASE_URL}/scores/image/streamextid/obs/${stream.externalId}`
                                                    );
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Score width</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder="1500"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(e, "1500");
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Score height</Form.Label>
                                    <Form.Row>
                                        <Col className="pl-0">
                                            <Form.Control
                                                placeholder="200"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Button
                                                onClick={(e) => {
                                                    copyToClipboard(e, "200");
                                                }}
                                            >
                                                Copy
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                            </Form>
                        </Alert>
                        <Alert className="mt-4" variant="warning">
                            <Alert.Heading>Important</Alert.Heading>
                            <ul>
                                <li>
                                    To connect to the camera you need to connect
                                    to the same network the camera is connected
                                    to.
                                </li>
                                <li>
                                    We highly recommend you connect to the
                                    network with an ethernet cable.
                                </li>
                                <li>
                                    Make sure the commentators are not too close
                                    to the camera as this will cause an echo.
                                </li>
                                <li>
                                    The stream will start immediately on{" "}
                                    {process.env.REACT_APP_PADELGO_WEBSITE_URL}{" "}
                                    when you start to stream from your streaming
                                    software regardless of stream scheduled
                                    start time.
                                </li>
                                <li>
                                    When the stream is stopped in in your
                                    streaming software the stream will end and
                                    the video will be uploaded.
                                </li>
                            </ul>
                        </Alert>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            {modalConfirmButtonPrimaryText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };

    return (
        <>
            <Button
                size={buttonSize}
                variant={buttonVariant}
                onClick={handleShow}
            >
                {buttonText}
            </Button>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        To stream manually you first need to install OBS Studio
                        on your computer. You can{" "}
                        <a
                            target="_blank"
                            title="Download OBS Studio"
                            href="https://obsproject.com/"
                        >
                            download OBS Studio here
                        </a>{" "}
                        - it's free. This is a software you can use to connect
                        directly to the camera. In OBS Studio you'll add score,
                        commentators, custom ads, etc. <br />
                        <br />
                        See the following manual on how to set-up OBS Studio on
                        your computer and configure your stream:{" "}
                        <a
                            target="_blank"
                            title="OBS installation and set-up"
                            href="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Stream%20with%20OBS%20v2.pdf"
                        >
                            OBS Studio installation and stream set-up guide
                        </a>
                        .
                    </p>
                    <p>
                        Use the following settings in your preferred streaming
                        software for the stream.
                    </p>
                    <Alert variant="secondary">
                        <Alert.Heading>Recommended settings</Alert.Heading>
                        <Form>
                            <Form.Group>
                                <Form.Label>Media Source Input</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            id="mediaSourceInput"
                                            placeholder={`rtsp://live:Padel123!@${stream.cameraIP}?enableaudio=1`}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(
                                                    e,
                                                    `rtsp://live:Padel123!@${stream.cameraIP}?enableaudio=1`
                                                );
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Server</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder={`${process.env.REACT_APP_PASSTHROUGH_URL}/passthrough`}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(
                                                    e,
                                                    `${process.env.REACT_APP_PASSTHROUGH_URL}/passthrough`
                                                );
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Stream key</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder={stream.externalId}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(
                                                    e,
                                                    stream.externalId
                                                );
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Bitrate</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder="5400"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(e, "5400");
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>CPU Usage Preset</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder="Veryfast"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(e, "Veryfast");
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Base (Canvas) Resolution
                                </Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder="1920x1080"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(e, "1920x1080");
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Output (Scaled) Resolution
                                </Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder="1920x1080"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(e, "1920x1080");
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Common FPS Values</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder="30"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(e, "30");
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Score URL</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder={`${process.env.REACT_APP_SCORES_API_BASE_URL}/scores/image/streamextid/obs/${stream.externalId}`}
                                            disabled
                                            aria-describedby="scoreHelpBlock"
                                        />
                                        <Form.Text id="scoreHelpBlock" muted>
                                            The score will be blank until it has
                                            been initiated for the stream. Go to
                                            "Manage stream" and activate the scoreboard.
                                        </Form.Text>
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(
                                                    e,
                                                    `${process.env.REACT_APP_SCORES_API_BASE_URL}/scores/image/streamextid/obs/${stream.externalId}`
                                                );
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Score width</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder="1500"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(e, "1500");
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Score height</Form.Label>
                                <Form.Row>
                                    <Col className="pl-0">
                                        <Form.Control
                                            placeholder="200"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <Button
                                            onClick={(e) => {
                                                copyToClipboard(e, "200");
                                            }}
                                        >
                                            Copy
                                        </Button>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        </Form>
                    </Alert>
                    <Alert className="mt-4" variant="warning">
                        <Alert.Heading>Important</Alert.Heading>
                        <ul>
                            <li>
                                To connect to the camera you need to connect to
                                the same network the camera is connected to.
                            </li>
                            <li>
                                We highly recommend you connect to the network
                                with an ethernet cable.
                            </li>
                            <li>
                                Make sure the commentators are not too close to
                                the camera as this will cause an echo.
                            </li>
                            <li>
                                The stream will start immediately on{" "}
                                {process.env.REACT_APP_PADELGO_WEBSITE_URL} when
                                you start to stream from your streaming software
                                regardless of stream scheduled start time.
                            </li>
                            <li>
                                When the stream is stopped in in your streaming
                                software the stream will end and the video will
                                be uploaded.
                            </li>
                        </ul>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        {modalConfirmButtonPrimaryText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ManualStreamModal;
