import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { GetStripeUrl } from '../services/Clubs';

const StripeAccountUrl = () => {

    const handleRedirect = async () => {
        const url = await GetStripeUrl(window.location.href, window.location.origin + '/settings').catch(() => { });
        window.location.href = url;
    }

    useEffect(() => {
        handleRedirect();
    }, [])

    return (
        <div style={{ backgroundColor: "#22AD5C", width: "100%", height: "100vh", display: "flex", alignItems: "center" }}>
            <div style={{ width: "50%", margin: "auto", bottom: "0", textAlign: 'center' }}>
                <img alt="" style={{ width: "100%" }} src="https://cdn.padelgo.tv/other/MATCHi%20TV%2001%20All-White.png"></img>
                <div className="mt-5">
                    <Spinner animation="border" variant="light" />
                </div>
            </div>
        </div>
    )
};

export default StripeAccountUrl;
