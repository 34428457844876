import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

/* Components */
import Header from "../components/Header";
import Wysiwyg from "../components/Wysiwyg";
import {
  Container,
  Spinner,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
} from "react-bootstrap";
import DatePicker from "react-datepicker";

/* Hooks */
import UseEvent from "../hooks/useEvent";
import useParentEvents from "../hooks/useParentEvents";
import useUser from "../hooks/useUser";
import useHasClubRole from "../hooks/useHasClubRole";

/* Services */
import {
  UpdateEvent,
  UploadEventImage,
  GetStripeAccount,
  GetClub,
  DeleteEventImage,
} from "../services/Clubs";

/* Third-party libraries */
import moment from "moment";
import addDays from "date-fns/addDays";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

/* Utilities */
import toBase64 from "../utilities/toBase64";

function EventsEdit({ user }) {
  let { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useUser();
  const [updatedEvent, setUpdatedEvent] = useState();
  const [event, refresh] = UseEvent({ id });
  const [validated, setValidated] = useState(false);
  const [isUpdatingEvent, setIsUpdatingEvent] = useState(false);
  const hasClubRole = useHasClubRole(user);
  const [alert, setAlert] = useState({
    show: false,
    variant: "success",
    heading: "",
    bodytext: "",
  });
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [startDateTime, setStartDateTime] = useState(new Date()); 
  const [localImageFile, setLocalImageFile] = useState(false);
  const [isPayPerViewEnabled, setIsPayPerViewEnabled] = useState(false);
  const [isPayPerViewEvent, setIsPayPerViewEvent] = useState(false);
  const [isLoadingStripeAccount, setIsLoadingStripeAccount] = useState(true);
  const imageInput = useRef(null);
  const [parentEvents, setParentEvents] = useParentEvents(
    currentUser?.result?.clubId
  );
  const defaultImage ="https://cdn.padelgo.tv/other/Group%2011893.jpg";
  const [clubObject, setClubObject] = useState({
    result: null,
    isLoading: true,
  });

  useEffect(() => {
    if (currentUser.result && currentUser.result.clubId) {
      GetClub(currentUser.result.clubId).then((result) => {
        setClubObject({ result: result, isLoading: false });
      });
    }
  }, [currentUser.isLoading]);

  const getDefaultEventImage = async () => {
    try {
      await DeleteEventImage(updatedEvent.eventExtId);
      setUpdatedEvent({
      ...updatedEvent,
      eventImage:
      defaultImage,
    });
    imageInput.current.value = "";
    } catch (error) {
      console.log(error);
    }    
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
    } else if (updatedEvent.endDate !== null && startDateTime > endDateTime) {
      setValidated(false);
        setAlert({
          ...alert,
        show: true,
        heading: 'Invalid Time',
        bodytext: 'End time cannot be earlier than start time.',
        variant: 'danger', 
        });
    } else {
      setIsUpdatingEvent(true);
      UpdateEvent(updatedEvent).then((response) => {
        if (localImageFile && updatedEvent.eventImage !== defaultImage) {
          toBase64(localImageFile).then((imageDataString) => {
            UploadEventImage(updatedEvent.eventExtId, imageDataString).then(
              (result) => {
                setAlert({
                  ...alert,
                  ...successAlertProperties(),
                });
                setIsUpdatingEvent(false);
              }
            );
          });
        } else if (updatedEvent.eventImage === defaultImage) {
            getDefaultEventImage();
            setAlert({
              ...alert,
              ...successAlertProperties(),
            });
            setIsUpdatingEvent(false);
          } else {
            setAlert({
              ...alert,
              ...successAlertProperties(),
            });
            setIsUpdatingEvent(false);
          }
      });
    }
  };

  const successAlertProperties = () => ({
    show: true,
    heading: "Event updated!",
    bodytext: "Event was successfully updated.",
    variant: "success",
  });
  
  const handleStartDateChange = (date) => {
    setStartDateTime(date);
    setUpdatedEvent({ ...updatedEvent, startDate: date });
    if (date > endDateTime) {
      const updatedEndDate = new Date(date);
      updatedEndDate.setDate(updatedEndDate.getDate() + 1);   
      setEndDateTime(updatedEndDate);
      setUpdatedEvent({ ...updatedEvent, endDate: updatedEndDate });
      setAlert({
        ...alert,
        show: false,
      });
    }
  };

  const filterTime = (time, referenceTime, minDurationInMinutes) => {
    const selectedTime = new Date(time);
    const referenceDateTime = new Date(referenceTime);
    const minValidEndTime = new Date(referenceDateTime);
    minValidEndTime.setMinutes(minValidEndTime.getMinutes() + minDurationInMinutes);
    return selectedTime >= minValidEndTime;
  };
  
  useEffect(() => {
    if (event.result) {
      setUpdatedEvent({
        ...event.result,
        startDate: moment.utc(event.result.startDate).local().format(),
        endDate: event.result.endDate
          ? moment.utc(event.result.endDate).local().format()
          : null,
      });
      setEndDateTime(event.result.endDate ? new Date(moment.utc(event.result.endDate).local().format()) : null);
      setStartDateTime(event.result.startDate ? new Date(moment.utc(event.result.startDate).local().format()) : null);
    }
  }, [event]);

  useEffect(() => {
    const fetchData = async () => {
      const stripeAccountResponse = await GetStripeAccount();

      if (stripeAccountResponse) {
        const stripeAccount = JSON.parse(stripeAccountResponse);

        if (stripeAccount) {
          if (stripeAccount.payouts_enabled) {
            setIsPayPerViewEnabled(true);
          }
        }
      }

      setIsLoadingStripeAccount(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (updatedEvent) {
      if (updatedEvent.price) {
        setIsPayPerViewEvent(true);
      } else {
        setIsPayPerViewEvent(false);
      }
    }
  }, [updatedEvent]);

  const handlePayPerViewEventToggle = (isPayPerView) => {
    setIsPayPerViewEvent(isPayPerView);

    if (!isPayPerView) {
      setUpdatedEvent({ ...updatedEvent, price: undefined });
    }
  };

  if (!hasClubRole) {
    navigate("/unauthorized");
  }

    return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className="mt-4 mb-2">
            <Alert
              show={alert.show}
              variant={alert.variant}
              onClose={() => setAlert({ ...alert, show: false })}
              dismissible
            >
              <Alert.Heading>{alert.heading}</Alert.Heading>
              <p>{alert.bodytext}</p>
            </Alert>
            <h3 className="title">Edit event</h3>
            {!event.isLoading && event.result && updatedEvent && (
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="formEventTitle">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter event name"
                    defaultValue={event.result?.eventName}
                    onChange={(e) => {
                      setUpdatedEvent({
                        ...updatedEvent,
                        eventName: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formEventDescription">
                  <Form.Label>Description</Form.Label>
                  <Wysiwyg
                    currentEvent={updatedEvent}
                    onEditorStateChange={(description) => {
                      setUpdatedEvent({ ...updatedEvent, description });
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.File
                    id="formEventThumbnail"
                    ref={imageInput}
                    accept="image/*"
                    label="Event thumbnail (1920x1080)"
                    onChange={(e) => {
                      setUpdatedEvent({
                        ...updatedEvent,
                        eventImage: URL.createObjectURL(e.target.files[0]),
                      });
                      setLocalImageFile(e.target.files[0]);
                    }}
                  />
                </Form.Group>
                {updatedEvent.eventImage && (
                  <>
                    <Form.Group>
                      <Image
                        src={updatedEvent.eventImage}
                        className="w-50 rounded"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          setUpdatedEvent({
                            ...updatedEvent,
                            eventImage:
                              defaultImage,
                          });
                          imageInput.current.value = "";
                        }}
                      >
                        Remove image
                      </Button>
                    </Form.Group>
                  </>
                )}
                <Form.Group controlId="formEventStart">
                  <Form.Label>Event start</Form.Label>
                  <DatePicker
                    className="form-control"
                    wrapperClassName="d-block"
                    selected={startDateTime}
                    onChange={(date) => handleStartDateChange(date)}
                    showTimeSelect
                    minDate={addDays(new Date(), 0)}
                    filterTime={(time) => filterTime(time, new Date(), 0)}
                    timeIntervals={5}
                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd HH:mm"
                  />
                </Form.Group>
                <Form.Group controlId="formEventEnd">
                  <Form.Label>Event end</Form.Label>
                    <DatePicker
                      className="form-control"
                      wrapperClassName="d-block"
                      selected={endDateTime}
                      onChange={(date) => {
                        setEndDateTime(date);
                        setUpdatedEvent({ ...updatedEvent, endDate: date })
                        setAlert({
                          ...alert,
                          show: false
                        });
                      }}
                      showTimeSelect
                      minDate={startDateTime}
                      filterTime={(time) => filterTime(time, startDateTime, 5)}
                      timeIntervals={5}
                      timeFormat="HH:mm"
                      dateFormat="yyyy-MM-dd HH:mm"
                    />
                </Form.Group>
                {clubObject && clubObject.result && clubObject.result.parentClubId !== null && (
                  <Form.Group controlId="formParentEvent">
                    <Form.Label>Federation event</Form.Label>
                    <Form.Control
                      as="select"
                      value={updatedEvent.parentEventId ?? "NO_EVENT"}
                      onChange={(e) =>
                        setUpdatedEvent({
                          ...updatedEvent,
                          parentEventId:
                            e.target.value === "NO_EVENT"
                              ? null
                              : e.target.value,
                        })
                      }
                    >
                      {(parentEvents?.result || []).map((item, index) => {
                        return (
                          <option key={index} value={item.eventId}>
                            {item.eventName}
                          </option>
                        );
                      })}
                      <option value={"NO_EVENT"}>
                        No federation event selected
                      </option>
                    </Form.Control>
                  </Form.Group>
                )}

                {clubObject && clubObject.result && clubObject.result.clubType === "Federation" && (
                  <Form.Group controlId="formEventIsChildClubAllowedToConnectCheck">
                    <Form.Check
                      type="checkbox"
                      label="Are clubs allowed to connect events?"
                      checked={updatedEvent.isChildClubAllowedToConnect}
                      onChange={(e) => {
                        setUpdatedEvent({
                          ...updatedEvent,
                          isChildClubAllowedToConnect: e.currentTarget.checked,
                        });
                      }}
                    />
                    <Form.Text className="text-muted">
                      Check this if you want clubs in your federation to be able
                      to connect events to this event.
                    </Form.Text>
                  </Form.Group>
                )}

                {!isLoadingStripeAccount && (
                  <>
                    <Form.Group controlId="formEventPayPerViewCheck">
                      <Form.Check
                        disabled={!isPayPerViewEnabled}
                        type="checkbox"
                        label="This is a Pay-per-View event"
                        checked={isPayPerViewEvent}
                        onChange={(e) => {
                          handlePayPerViewEventToggle(e.currentTarget.checked);
                        }}
                      />
                      {isPayPerViewEnabled && (
                        <Form.Text className="text-muted">
                          Check this if the event is a Pay-per-View event. This
                          will require the user to purchase access to the
                          streams associated with this event.
                        </Form.Text>
                      )}
                      {!isPayPerViewEnabled && (
                        <Form.Text className="text-danger">
                          You need to set-up your payout account in Settings
                          first to enable Pay-per-View events.
                        </Form.Text>
                      )}
                    </Form.Group>
                    {isPayPerViewEvent && (
                      <Form.Group>
                        <Form.Group controlId="formEventName">
                          <Form.Label>Price (€)</Form.Label>
                          <Form.Control
                            required
                            defaultValue={updatedEvent.price * 0.01}
                            type="number"
                            placeholder="Pay-per-View price in €"
                            min="1"
                            step="any"
                            onChange={(e) => {
                              setUpdatedEvent({
                                ...updatedEvent,
                                price: parseFloat(e.target.value) * 100,
                              });
                            }}
                          />
                        </Form.Group>
                      </Form.Group>
                    )}
                  </>
                )}
                <Button
                  disabled={isUpdatingEvent}
                  className="mt-2"
                  variant="primary"
                  type="submit"
                >
                  {isUpdatingEvent && (
                    <>
                      <Spinner
                        className="mr-1"
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Just a second...
                    </>
                  )}
                  {!isUpdatingEvent && <>Save</>}
                </Button>
              </Form>
            )}
          </Col>
        </Row>
        <Row>
          {event.isLoading && (
            <Col className="d-flex justify-content-center align-items-center mt-5">
              <Spinner animation="border" />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

export default EventsEdit;
