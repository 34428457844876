import React from "react";

/* Components */
import Header from "../components/Header";
import ContactSupport from "../components/ContactSupport";

/* Styling */
import "react-datepicker/dist/react-datepicker.css";
import "../styles/card.css";
import "../styles/button.css";

export default function HowToSchedule() {
    return (
        <>
            <Header />

            <div className="container">
                <div className="mt-4 mb-4">
                    <div className="title header h2">How to schedule a stream</div>
                    <div>
                        <h6 className="title mt-5">Introduction</h6>
                        <p>A basic overview of how MATCHi TV studio works and what you can expect to find in the tool.</p>
                        <div
                            style={{
                                position: "relative",
                                paddingTop: "66.66666666666666%;",
                            }}
                        >
                            <iframe style={{ border: "none" }} src="https://iframe.mediadelivery.net/embed/11915/77de9c53-04a7-46ae-89d3-e40ea9b03029?autoplay=false" loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                        </div>

                        <h6 className="title mt-5">Scheduling streams</h6>
                        <p>We go through the primary functionality of MATCHi TV studio, which is how you can schedule one or many streams for your venue.</p>
                        <div
                            style={{
                                position: "relative",
                                paddingTop: "66.66666666666666%;",
                            }}
                        >
                            <iframe style={{ border: "none" }} src="https://iframe.mediadelivery.net/embed/11915/dedc9dad-2e7a-4fc3-9151-9fcd6dde70bc?autoplay=false" loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                        </div>
                        <div className="margin-top">
                            <p style={{ marginBottom: 15 }}>
                                See the following manual on how to schedule a stream:{" "}
                                <a target="_blank" title="MATCHi TV Studio Guide" href="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Guide%20v2.pdf">
                                    MATCHi TV Studio - Guide
                                </a>
                                .
                            </p>
                        </div>
                    </div>
                    <div>
                        <iframe src="https://cdn.padelgo.tv/guides/MATCHi%20TV%20Studio%20-%20Guide%20v2.pdf" title="MATCHi TV Studio Guide" height="600" width="100%"></iframe>
                    </div>
                    <ContactSupport />
                </div>
            </div>
        </>
    );
}
