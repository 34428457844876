import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

/* Components */
import Header from "../components/Header";

/* Third-party libraries */
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import moment from "moment";

import useHasClubRole from "../hooks/useHasClubRole";

import { GetEventsStreams } from "../services/Streams";
import {
  RemoveStreamFromEvent,
  RemoveHighlightFromEvent,
} from "../services/Clubs";

export default function EventsStreamsOverview({ user }) {
  const [streams, setStreams] = useState({ isLoading: true, result: [] });
  const [alert, setAlert] = useState({
    show: false,
    variant: "success",
    heading: "",
    bodytext: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const hasClubRole = useHasClubRole(user);

  const removeStreamFromEvent = async (externalId, mediaType) => {
    try {
      markStreamItemAsBeingRemoved(externalId);
      if (mediaType === "Highlight") {
        await RemoveHighlightFromEvent(id, externalId);
      } else {
        await RemoveStreamFromEvent(id, externalId);
      }

      removeStreamFromList(externalId);

      setAlert({
        ...alert,
        show: true,
        heading: "Stream removed!",
        bodytext:
          "Stream was successfully removed from event. Please note that it may take a minute or two before the stream disappears from the event.",
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        ...alert,
        show: true,
        heading: "Error",
        bodytext: "Something went wrong. Please try again.",
        variant: "danger",
      });
    }
  };

  const markStreamItemAsBeingRemoved = (externalId) => {
    const newStreams = streams.result.map((item) => {
      if (item.externalId === externalId) {
        item.isBeingRemoved = true;
      }
      return item;
    });
    setStreams({ ...streams, result: newStreams });
  };

  const removeStreamFromList = (externalId) => {
    const newStreams = streams.result.filter((item) => {
      return item.externalId !== externalId;
    });
    setStreams({ ...streams, result: newStreams });
  };

  /* Load all events and their streams on page load */
  useEffect(() => {
    const load = async () => {
      try {
        const streams = await GetEventsStreams(id, 1, 200);
        setStreams({ result: streams, isLoading: false });
      } catch (error) {
        console.error(error);
      }
    };

    load();
  }, []);

  if (!hasClubRole) {
    navigate("/unauthorized");
  }

  return (
    <>
      <Header />
      <Container>
        <Row className="mt-4 mb-2">
          <Col>
            <h3 className="title">Streams for event</h3>
          </Col>
        </Row>
        <Row>
          {streams.isLoading && (
            <Col className="d-flex justify-content-center align-items-center mt-5">
              <Spinner animation="border" />
            </Col>
          )}
          {!streams.isLoading && (
            <Col>
              <Alert
                show={alert.show}
                variant={alert.variant}
                onClose={() => setAlert({ ...alert, show: false })}
                dismissible
              >
                <Alert.Heading>{alert.heading}</Alert.Heading>
                <p>{alert.bodytext}</p>
              </Alert>
              {streams && streams.result && streams.result.length === 0 && (
                <p>No streams added</p>
              )}
              {streams && streams.result && streams.result.length > 0 && (
                <Table responsive className="table-overflow-text-rows">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Channel</th>
                      <th>Thumbnail</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {streams.result.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.internalId}</td>
                          <td>{item.mediaType}</td>
                          <td title={item.description}>{item.description}</td>
                          <td>
                            {item.startDateTime
                              ? moment
                                  .utc(item.startDateTime)
                                  .local()
                                  .format("D/M HH:mm")
                              : ""}
                          </td>
                          <td>
                            {item.endDateTime
                              ? moment
                                  .utc(item.endDateTime)
                                  .local()
                                  .format("D/M HH:mm")
                              : ""}
                          </td>
                          <td>{item.channel}</td>
                          <td>
                            <img src={item.thumbnailURL} height="30" />
                          </td>
                          <td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                removeStreamFromEvent(
                                  item.externalId,
                                  item.mediaType
                                );
                              }}
                              disabled={item.isBeingRemoved}
                              size="sm"
                              variant="outline-primary"
                              type="button"
                            >
                              {item.isBeingRemoved && (
                                <>
                                  <Spinner
                                    className="mt-1 mr-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="light"
                                  />
                                  <span style={{ color: "white" }}>
                                    Removing...
                                  </span>
                                </>
                              )}
                              {!item.isBeingRemoved && <>Remove</>}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}
