import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { StartStream, StopStream, DeleteStream } from "../services/Studio";
import ManualStreamModal from "./ManualStreamModal";
import ConfirmModal from "./ConfirmModal";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import useEvents from "../hooks/useEvents";
import useUser from "../hooks/useUser";
import "../styles/table-text.css";

function StreamsTable({ streams, setList }) {
  const user = useUser();
  const [events, refresh] = useEvents(user?.result?.clubId);
  const toggleItemLoading = (itemToToggleLoading) => {
    const updatedList = streams.map((item) => {
      if (item.id === itemToToggleLoading.id) {
        const updatedItem = {
          ...item,
          isLoading: !item.isLoading,
        };
        return updatedItem;
      }
      return item;
    });
    setList(updatedList);
  };

  const startStreamItem = async (itemToStart) => {
    toggleItemLoading(itemToStart);
    await StartStream(itemToStart.externalId);
    const updatedList = streams.map((item) => {
      if (item.id === itemToStart.id) {
        const updatedItem = {
          ...item,
          isLive: true,
          isPossibleToStop: true,
          isPossibleToStart: false,
          isLoading: false,
        };
        return updatedItem;
      }
      return item;
    });
    setList(updatedList);
  };

  const stopStreamItem = async (itemToStop) => {
    toggleItemLoading(itemToStop);
    await StopStream(itemToStop.externalId);
    const updatedList = streams.map((item) => {
      if (item.id === itemToStop.id) {
        const updatedItem = {
          ...item,
          isLive: true,
          isPossibleToStop: false,
          isPossibleToStart: false,
          isLoading: false,
        };
        return updatedItem;
      }
      return item;
    });
    setList(updatedList);
  };

  const deleteStreamItem = async (itemToDelete) => {
    toggleItemLoading(itemToDelete);
    await DeleteStream(itemToDelete.externalId);
    const updatedList = streams.filter((item) => item.id !== itemToDelete.id);
    setList(updatedList);
  };

  if (streams.length > 0) {
    return (
      <>
        <Table responsive striped className="table-overflow-text-rows">
          <thead>
            <tr>
              <th>Name</th>
              <th>Event</th>
              <th>Time</th>
              <th>Court</th>
              <th>Started</th>
              <th>Is Private</th>
              <th>Streaming software</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {streams.map((item, index) => {
              (events?.result || []).map((event) => {
                if (event.eventId === item.eventId) {
                  item.eventName = event.eventName;
                }
              });
              return (
                <tr key={index}>
                  <td title={item.title}>{item.title}</td>
                  <td title={item.eventName ?? "-"}>{item.eventName ?? "-"}</td>
                  <td>
                    {moment.utc(item.startDate).local().format("D/M HH:mm")} -{" "}
                    {moment.utc(item.endDate).local().format("HH:mm")}
                  </td>
                  <td title={item.court}>{item.court}</td>
                  <td>
                    {item.isLive && <>Yes</>}
                    {!item.isLive && <>No</>}
                  </td>
                  <td>
                  {item.isPrivate && <>Yes</>}
                    {!item.isPrivate && <>No</>}
                  </td>
                  <td>
                    {item.isStudio && (
                      <ManualStreamModal
                        stream={item}
                        buttonSize="sm"
                        buttonVariant="outline-primary"
                        buttonText="How to connect"
                        modalConfirmButtonPrimaryText="OK, got it!"
                        modalTitle="How to manually stream with an external streaming software"
                      ></ManualStreamModal>
                    )}
                    {!item.isStudio && <>No</>}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_PADELGO_WEBSITE_URL}/watch?s=${item.externalId}`,
                          "_blank"
                        );
                      }}
                    >
                      Go to stream
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_PADELGO_WEBSITE_URL}/manage/${item.externalId}`,
                          "_blank"
                        );
                      }}
                    >
                      Manage stream
                    </Button>
                  </td>
                  {!item.isLoading && (
                    <>
                      <td>
                        {item.isPossibleToStop && !item.isStudio && (
                          <Button
                            className="float-right"
                            size="sm"
                            variant="outline-danger"
                            onClick={async (e) => {
                              e.preventDefault();
                              await stopStreamItem(item);
                            }}
                          >
                            Stop
                          </Button>
                        )}
                        {item.isPossibleToStart && !item.isStudio && (
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={async (e) => {
                              e.preventDefault();
                              startStreamItem(item);
                            }}
                          >
                            Start
                          </Button>
                        )}
                      </td>
                      <LinkContainer to={`/streams/edit/${item.externalId}`}>
                        <td>
                          <Button size="sm" variant="outline-primary">
                            Edit
                          </Button>
                        </td>
                      </LinkContainer>
                      <td>
                        <ConfirmModal
                          buttonText="Delete"
                          buttonVariant="danger"
                          modalTitle="Delete stream"
                          modalBodyText="Are you sure you want to delete the stream? This can't be undone!"
                          modalCancelText="Cancel"
                          modalConfirmText="Yes, delete it!"
                          modalConfirmButtonVariant="outline-danger"
                          action={async () => {
                            await deleteStreamItem(item);
                          }}
                        ></ConfirmModal>
                      </td>
                    </>
                  )}
                  {item.isLoading && (
                    <td colSpan={3}>
                      <Spinner animation="border" />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  } else {
    return (
      <>
        <p className="text-center mb-5">No streams</p>
      </>
    );
  }
}

export default StreamsTable;
