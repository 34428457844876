import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

function SuccessModal({ showModal, closeModalPrimaryAction, closeModalSecondaryAction, modalTitle, modalBodyText, modalConfirmButtonPrimaryText, modalConfirmButtonSecondaryText }) {

    const [show, setShow] = useState(false);
    const handleClosePrimary = () => { setShow(false); closeModalPrimaryAction(); }
    const handleCloseSecondary = () => { setShow(false); closeModalSecondaryAction(); }

    useEffect(() => {
        setShow(showModal);
    }, [showModal]);

    return (
        <Modal show={show} onHide={() => handleCloseSecondary()}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalBodyText}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { handleCloseSecondary(); }}>
                    {modalConfirmButtonSecondaryText}
                </Button>
                <Button variant="primary" onClick={() => { handleClosePrimary(); }}>
                    {modalConfirmButtonPrimaryText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SuccessModal;