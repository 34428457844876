import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* Components */
import Header from "../components/Header";

/* Third-party libraries */
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

/* Hooks */
import useStreams from "../hooks/useStreams";
import StreamsTable from "../components/StreamsTable";
import useHasClubRole from "../hooks/useHasClubRole";

function StreamsOverview({ user }) {
  const [streams, setStreams] = useStreams();
  const [filteredStreams, setFilteredStreams] = useState();
  const [showScheduled, setShowScheduled] = useState(true);
  const [showLive, setShowLive] = useState(true);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showPrivate, setShowPrivate] = useState(false);
  const [message, setMessage] = useState({ display: false, message: "" });
  const [groupByCourt, setGroupByCourt] = useState(false);
  const navigate = useNavigate();
  const hasClubRole = useHasClubRole(user);

  if (!hasClubRole) {
    navigate("/unauthorized");
  }

  useEffect(() => {
    if (streams && streams.result && streams.result.length > 0) {
      filterStreams();
    }
  }, [streams]);

  const filterStreams = () => {
    let result = streams.result;

    if (!showScheduled) {
      result = result.filter((s) => s.actualStartDate != null);
    }

    if (!showCompleted) {
      result = result.filter((s) => s.actualEndDate === null);
    }

    if (!showLive) {
      result = result.filter((s) => s.isLive != true);
    }

    if (!showPrivate) {
      result = result.filter((s) => s.isPrivate != true);
    }

    if (groupByCourt) {
      result.sort((a, b) => {
        if (a.court === b.court) {
          return a.startDate > b.startDate;
        }
        return a.court > b.court ? 1 : -1;
      });
    } else {
      result.sort((a, b) => a.startDate > b.startDate);
    }

    setFilteredStreams(result);
  };

  useEffect(() => {
    if (streams && streams.result && streams.result.length > 0) {
      filterStreams();
    }
  }, [showScheduled, showLive, showCompleted, showPrivate, groupByCourt]);

  return (
    <>
      <Header />
      <Container>
        <Row className="mt-4 mb-2">
          <Col>
            <h3 className="title">Streams</h3>
          </Col>
          <Col className="d-flex flex-row-reverse">
            <LinkContainer to="/streams/schedule">
              <Button variant="outline-primary">Schedule stream</Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 text-center text-lg-left">
            <h6>Filter</h6>
            <Form>
              <Form.Check
                defaultChecked={true}
                inline
                label="Scheduled"
                name="filter"
                type="checkbox"
                id="filter-scheduled"
                onChange={(e) => {
                  setShowScheduled(e.currentTarget.checked);
                }}
              />
              <Form.Check
                defaultChecked={true}
                inline
                label="Live"
                name="filter"
                type="checkbox"
                id="filter-live"
                onChange={(e) => {
                  setShowLive(e.currentTarget.checked);
                }}
              />
              <Form.Check
                defaultChecked={false}
                inline
                label="Completed"
                name="filter"
                type="checkbox"
                id="filter-completed"
                onChange={(e) => {
                  setShowCompleted(e.currentTarget.checked);
                }}
              />
              <Form.Check
                defaultChecked={false}
                inline
                label= "Show Private"
                name= "filter"
                type= "checkbox"
                id= "filter-is-private"
                onChange={(e) => {
                  setShowPrivate(e.currentTarget.checked);
                }}
                />
            </Form>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3 mb-5 text-center text-lg-left">
            <h6>Group</h6>
            <Form>
              <Form.Check
                defaultChecked={false}
                inline
                label="Group by court"
                name="groupByCourt"
                type="checkbox"
                id="group-court"
                onChange={(e) => {
                  setGroupByCourt(e.currentTarget.checked);
                }}
              />
            </Form>
          </Col>
        </Row>
        <Row>
          {streams.isLoading && (
            <Col className="d-flex justify-content-center align-items-center mt-5">
              <Spinner animation="border" />
            </Col>
          )}
          {!streams.isLoading && (
            <Col>
              {streams && streams.result && streams.result.length === 0 && (
                <p className="text-center">No streams scheduled</p>
              )}
              {streams &&
                streams.result &&
                streams.result.length > 0 &&
                filteredStreams && (
                  <>
                    <StreamsTable
                      streams={filteredStreams}
                      setList={setFilteredStreams}
                    />
                  </>
                )}
            </Col>
          )}
        </Row>
      </Container>
      {message.display && (
        <div
          className="position-absolute"
          style={{ top: "25px", left: "25px", right: "25px", margin: "auto" }}
        >
          <Alert
            variant="success"
            dismissible
            onClose={() => {
              setMessage({ display: false, message: "" });
            }}
          >
            {message.message}
          </Alert>
        </div>
      )}
    </>
  );
}

export default StreamsOverview;
