import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Spinner,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Image,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

/* Components */
import Header from "../components/Header";
import SuccessModal from "../components/SuccessModal";
import ErrorAlert from "../components/ErrorAlert";
import HelpCard from "../components/HelpCard";


/* Styling */
import "react-datepicker/dist/react-datepicker.css";
import "../styles/card.css";
import "../styles/button.css";

/* Hooks */
import useUser from "../hooks/useUser";

/* Services */
import {
  CreateEvent,
  UploadEventImage,
  GetStripeAccount,
} from "../services/Clubs";

/* Utilities */
import toBase64 from "../utilities/toBase64";
import { Grid } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function HelpAndTutorials() {
  return (
    <>
      <Header />

      <div className="container">
        <div className="mt-4 mb-4">
          <div className="title header h2">Help and tutorials</div>
        </div>
        <div>
          <p>
            Here are tutorials on how to use MATCHi TV studio.<br></br>
            <br></br>
            To get started with MATCHi TV studio you need a MATCHi TV studio
            account. If you're a club owner and haven't received one yet, please
            reach out to <a href="support@matchi.tv">support@matchi.tv</a> and
            we'll get you set-up.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <HelpCard
              img="https://cdn.padelgo.tv/other/padelevent640.jpg"
              title="How to create an event"
              body="with MATCHi TV studio"
              link="/helpandtutorials/event"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <HelpCard
              img="https://cdn.padelgo.tv/other/stream.jpg"
              title="How to schedule a stream"
              body="with MATCHi TV studio"
              link="/helpandtutorials/schedule"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <HelpCard
              img="https://cdn.padelgo.tv/other/OBS-Studio-logo-on-blue-background.jpg"
              title="How to stream with OBS"
              body="via MATCHi TV studio"
              link="/helpandtutorials/obs"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <HelpCard
              img="https://cdn.padelgo.tv/other/network.jpg"
              title="Technical help"
              body="This is what you can do if you're having technical issues"
              link="/helpandtutorials/technical"
            />
          </div>
        </div>
      </div>
    </>
  );
}
