import React, { useEffect, useState } from "react";
import {
  Container,
  Spinner,
  Row,
  Col,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

/* Components */
import Header from "../components/Header";
import SuccessModal from "../components/SuccessModal";
import ErrorAlert from "../components/ErrorAlert";
import MultipleCourtModal from "../components/MultipleCourtModal";
import MultipleSuccessModal from "../components/MultipleSuccessModal";

/* Third-party libraries */
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addDays from "date-fns/addDays";

/* Styling */
import "react-datepicker/dist/react-datepicker.css";
import "../styles/material-icons.css";

/* Hooks */
import useCourts from "../hooks/useCourts";
import useUser from "../hooks/useUser";
import useEvents from "../hooks/useEvents";
import useScheduleStream from "../hooks/useScheduleStream";
import useHasClubRole from "../hooks/useHasClubRole";

function StreamsSchedule({ user }) {
  const courts = useCourts();
  const currentUser = useUser();
  const [events, refresh] = useEvents(currentUser?.result?.clubId);
  const [channel, setChannel] = useState(null);
  const currentDate = new Date();
  const navigate = useNavigate();
  const streamInitialState = {
    description: "",
    court: null,
    startStreamDirectly: false,
    isPrivate: false,
    startDate: setHours(
      setMinutes(new Date(), currentDate.getMinutes()),
      currentDate.getHours()
    ),
    endDate: setHours(
      setMinutes(new Date(), currentDate.getMinutes()),
      currentDate.getHours() + 1
    ),
    streamManually: false,
    channel: channel,
    eventId: null,
  };
  const [stream, setStream] = useState(streamInitialState);
  const hasClubRole = useHasClubRole(user);

  const [
    setScheduleRequest,
    scheduling,
    responseMessage,
    scheduleRequestList,
    isSchedulingStream,
  ] = useScheduleStream();
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [displayMultipleSuccessModal, setDisplayMultipleSuccessModal] =
    useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    title: null,
    message: null,
  });

  const [isApprovedConditions, setIsApprovedConditions] = useState(false);
  const [selectedCourt, setSelectedCourt] = useState("");
  const [selectedCourtsInModal, setSelectedCourtsInModal] = useState([]);
  const [multipleCourts, setMultipleCourts] = useState([]);
  const [isMultipleCourts, setIsMultipleCourts] = useState(false);
  const [modalText, setModalText] = useState("");
  const [nameExample, setNameExample] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);

  useEffect(() => {
    if (isMultipleCourts) {
      const descriptionList2 = new Array();
      for (let i = 0; multipleCourts.length > i; i++) {
        descriptionList2.push(
          stream.description + " - " + multipleCourts[i].description
        );
      }
      setNameExample(
        stream.description + " - " + multipleCourts[0].description
      );
      setDescriptionList(descriptionList2);
    }
  }, [stream, isMultipleCourts]);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    if (currentUser.result && currentUser.result.channel) {
      setChannel(currentUser.result.channel.name);
    }
  }, [currentUser.isLoading]);

  useEffect(() => {
    if (
      !courts.isLoading &&
      courts.result &&
      courts.result.length > 0 &&
      !isMultipleCourts
    ) {
      setStream({ ...stream, court: courts.result[0].courtId });
    }
  }, [courts.isLoading]);

  useEffect(() => {
    if (courts.result) {
      const courtTest = courts?.result?.find((c) => {
        return c.courtId === stream.court;
      });
      if (courtTest) {
        setSelectedCourt(courtTest.description);
      }
    }
  }, [stream]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    setShowError(false);
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
      return;
    }

    const submitableList = [];
    if (multipleCourts.length !== 0) {
      multipleCourts.forEach((court, i) => {
        const submitObject = {
          description: descriptionList[i],
          court: court.courtId,
          startStreamDirectly: stream.startStreamDirectly,
          startDate: stream.startDate,
          endDate: stream.endDate,
          streamManually: stream.streamManually,
          channel: channel,
          eventId: stream.eventId,
          isPrivate: stream.isPrivate,
        };
        submitableList.push(submitObject);
      });
    } else {
      const submitObject = {
        description: stream.description,
        court: stream.court,
        startStreamDirectly: stream.startStreamDirectly,
        startDate: stream.startDate,
        endDate: stream.endDate,
        streamManually: stream.streamManually,
        channel: channel,
        eventId: stream.eventId,
        isPrivate: stream.isPrivate,
      };
      submitableList.push(submitObject);
    }

    setScheduleRequest(submitableList);
    setDisplayMultipleSuccessModal(true);
  };

  if (!hasClubRole) {
    navigate("/unauthorized");
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col className="mt-4 mb-2">
            <ErrorAlert
              showError={showError}
              heading={errorMessage.title}
              bodyText={errorMessage.message}
            />
            <h3 className="title">Schedule stream</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formStreamTitle">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter stream name"
                  onChange={(e) => {
                    setStream({ ...stream, description: e.target.value });
                  }}
                />
                <Form.Text className="text-muted" hidden={!isMultipleCourts}>
                  Example: {nameExample}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formStreamTitle">
                <Form.Label>Channel</Form.Label>
                <Form.Control
                  required
                  disabled
                  type="text"
                  placeholder="Loading..."
                  value={channel}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Court</Form.Label>
                {isMultipleCourts && (
                  <Form.Control
                    required
                    disabled
                    type="text"
                    value={multipleCourts.map((selectedCourts) => {
                      return " " + selectedCourts.description;
                    })}
                  />
                )}
                {!isMultipleCourts && (
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      setStream({ ...stream, court: e.target.value });
                      console.log(e.target.value);
                    }}
                  >
                    {courts &&
                      courts.result &&
                      courts.result.map((item, index) => {
                        return (
                          <option key={index} value={item.courtId}>
                            {item.description}
                          </option>
                        );
                      })}
                  </Form.Control>
                )}
                <Form>
                  <Row>
                    <Col xs={11}>
                      <MultipleCourtModal
                        buttonSize="sm"
                        buttonVariant="secondary"
                        buttonText="Select multiple courts"
                        modalConfirmButtonPrimaryText="Confirm choices!"
                        modalTitle="Select multiple courts"
                        setMultipleCourts={setMultipleCourts}
                        setIsMultipleCourts={setIsMultipleCourts}
                        setSelectedCourt={setSelectedCourt}
                        isMultipleCourts={isMultipleCourts}
                        setSelectedCourtsInModal={setSelectedCourtsInModal}
                        selectedCourtsInModal={selectedCourtsInModal}
                      ></MultipleCourtModal>
                    </Col>
                    <Col>
                      <Button
                        className="clear"
                        size="sm"
                        variant="outline-secondary"
                        type="button"
                        onClick={() => {
                          setSelectedCourt([]);
                          setMultipleCourts([]);
                          setSelectedCourtsInModal([]);
                          setIsMultipleCourts(false);
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Form.Group>
              <Form.Group>
                <Form.Label>Event</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setStream({ ...stream, eventId: e.target.value });
                  }}
                >
                  <option value={null}>Is this stream part of an event?</option>
                  {events &&
                    events.result &&
                    events.result.map((item, index) => {
                      return (
                        <option key={index} value={item.eventId}>
                          {item.eventName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formIsPrivateStreamCheckbox">
                <Form.Check
                  type="checkbox"
                  label= "Private stream"
                  onChange={(e) => {
                    setStream({...stream, isPrivate: e.target.checked });
                  }}
                />
                <Form.Text className="text-muted">
                  Checking this means that the stream will be visible only to you.
                </Form.Text>
              </Form.Group>
              <Form.Group
                noValidate
                controlId="formStartStreamDirectlyCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  label="Start stream directly"
                  onChange={(e) => {
                    setStream({
                      ...stream,
                      startStreamDirectly: e.target.checked,
                      startDate: new Date(),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group controlId="formStreamStart">
                <Form.Label>Stream start</Form.Label>
                <DatePicker
                  disabled={stream.startStreamDirectly}
                  className="form-control"
                  wrapperClassName="d-block"
                  selected={stream.startDate}
                  onChange={(date) => setStream({ ...stream, startDate: date })}
                  showTimeSelect
                  minDate={addDays(new Date(), 0)}
                  filterTime={filterPassedTime}
                  timeIntervals={5}
                  timeFormat="HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                />
              </Form.Group>
              <Form.Group controlId="formStreamEnd">
                <Form.Label>Stream end</Form.Label>
                <DatePicker
                  className="form-control"
                  wrapperClassName="d-block"
                  selected={stream.endDate}
                  onChange={(date) => setStream({ ...stream, endDate: date })}
                  showTimeSelect
                  minDate={addDays(new Date(), 0)}
                  maxDate={stream.startDate}
                  filterTime={filterPassedTime}
                  timeIntervals={5}
                  timeFormat="HH:mm"
                  dateFormat="yyyy-MM-dd HH:mm"
                />
              </Form.Group>
              <Form.Group controlId="formStreamManuallyCheckbox">
                <Form.Check
                  type="checkbox"
                  label="I'll be streaming with an external streaming software (e.g., OBS Studio or vMix.) "
                  onChange={(e) => {
                    setStream({ ...stream, streamManually: e.target.checked });
                  }}
                />
                <Form.Text className="text-muted">
                  Only check this if you will be streaming with an external
                  streaming software from a PC or Mac. If you are using OBS
                  Studio, you can find instructions under "How to stream with
                  OBS Studio" manual under the "Help and tutorials" section.
                </Form.Text>
              </Form.Group>
              <Row>
                <Col className="mt-3">
                  <Alert variant="warning">
                    <Form.Group controlId="formConsent">
                      <Form.Check
                        type="checkbox"
                        label="I confirm that I have informed the players and have their consent"
                        onChange={(e) => {
                          setIsApprovedConditions(e.currentTarget.checked);
                        }}
                      />
                      <Form.Text className="text-muted">
                        You must inform the players that you will stream their
                        game. If you do not have their consent{" "}
                        <b>you are not allowed</b> to stream the game.
                      </Form.Text>
                    </Form.Group>
                  </Alert>
                </Col>
              </Row>
              <Button
                disabled={isSchedulingStream || !isApprovedConditions}
                className="mt-2"
                variant="primary"
                type="submit"
              >
                {isSchedulingStream && (
                  <>
                    <Spinner
                      className="mr-1 mb-1"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Just a second...
                  </>
                )}
                {!isSchedulingStream && <>Schedule</>}
              </Button>
            </Form>
          </Col>
        </Row>

        <SuccessModal
          showModal={displaySuccessModal}
          modalTitle="Stream scheduled!"
          modalBodyText={`Your stream ${stream.description} was successfully scheduled.`}
          modalConfirmButtonPrimaryText="To overview"
          modalConfirmButtonSecondaryText="Schedule another stream"
          closeModalPrimaryAction={() => navigate("/streams/overview")}
          closeModalSecondaryAction={() => {
            setStream(streamInitialState);
          }}
        />

        <MultipleSuccessModal
          showModal={displayMultipleSuccessModal}
          submitableList={scheduleRequestList}
          responseMessages={responseMessage}
          isSchedulingStream={isSchedulingStream}
          modalTitle="Scheduling streams"
          modalText={modalText}
          isMultipleCourts={isMultipleCourts}
          nameExample={nameExample}
          scheduling={scheduling}
          multipleCourts={multipleCourts}
          modalConfirmButtonPrimaryText="To overview"
          modalConfirmButtonSecondaryText="Schedule another stream"
          closeModalPrimaryAction={() => navigate("/streams/overview")}
          closeModalSecondaryAction={() => {
            setDisplayMultipleSuccessModal(false);
          }}
        />
      </Container>
    </>
  );
}

export default StreamsSchedule;
